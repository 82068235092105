import React, {Fragment} from "react";
import {Tooltip,} from "@vokymlak/kabinet-ui";

export const ProjectContractsTotal = (props) => {
  const {
    list,

    listControlPoints,

    isInfo,
    isAmount,
    isAddition,

    controlGroups,
    isViewControlGroup,

    getListTotal,

    isClient,

    total,

    isCtExecution,
  } = props;

  return (
    !!list && (
      <Fragment>
        <tr
          className={
            "Table__tr Table__tr_padding Table__tr_border"
          }
        >
          {isClient && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff9c450",
                }}
              >
              </td>
            </>
          )}

          {!isClient && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff9c450",
                }}
              >
              </td>
            </>
          )}

          {!isClient && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff9c450",
                }}
              >
              </td>
            </>
          )}

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#fff9c450",
              cursor: "pointer",
            }}
          >
          </td>

          {!isClient && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff9c450",
                  cursor: "pointer",
                }}
              >
              </td>
            </>
          )}

          <td
            className="Table__td Table__td_border"
            style={{
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              cursor: "pointer",
            }}
          >
          </td>
          {(isInfo || isClient) && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                  cursor: "pointer",
                }}
              >
              </td>
              {/*<td*/}
              {/*  className="Table__td Table__td_border"*/}
              {/*  style={{*/}
              {/*    backgroundColor: "#bbdefb50",*/}
              {/*    cursor: "pointer",*/}
              {/*  }}*/}
              {/*>*/}
              {/*</td>*/}
            </>
          )}
          <td
            className="Table__td Table__td_border"
            style={{
              cursor: "pointer",
              width: 190,
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border Table__td_sticky"
            style={{
              cursor: "pointer",
              backgroundColor: "#f1f1f1",
              width: 190,
            }}
          >
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
              cursor: "pointer",
            }}
          >
            <Tooltip
              text={"Начальная цена"}
              style={{width: "100%", height: "100%"}}
            >
              <div className="Td__text Td__text_center">
                {/*{(!!getListTotal(list).startingPrice &&*/}
                {/*  new Intl.NumberFormat("ru-RU").format(*/}
                {/*    (getListTotal(list).startingPrice / 100).toFixed(2)*/}
                {/*  )) ||*/}
                {/*  "-"}*/}
                {(!!total && !!total.starting_price &&
                    new Intl.NumberFormat("ru-RU").format(
                      (total.starting_price / 100).toFixed(2)
                    )) ||
                  "-"}

              </div>
            </Tooltip>
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
              cursor: "pointer",
            }}
          >
            <Tooltip
              text={"Цена договора"}
              style={{width: "100%", height: "100%"}}
            >
              <div className="Td__text Td__text_center">
                {/*{(!!getListTotal(list).amount &&*/}
                {/*    new Intl.NumberFormat("ru-RU").format(*/}
                {/*      (getListTotal(list).amount / 100).toFixed(2)*/}
                {/*    )) ||*/}
                {/*  "-"}*/}
                {(!!total && !!total.amount &&
                    new Intl.NumberFormat("ru-RU").format(
                      (total.amount / 100).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </Tooltip>
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
              cursor: "pointer",
            }}
          >
            <Tooltip
              text={"Сумма текущего года"}
              style={{width: "100%", height: "100%"}}
            >
              <div className="Td__text Td__text_center">
                {/*{(!!getListTotal(list).amountCurrentYear &&*/}
                {/*    new Intl.NumberFormat("ru-RU").format(*/}
                {/*      (getListTotal(list).amountCurrentYear / 100).toFixed(2)*/}
                {/*    )) ||*/}
                {/*  "-"}*/}

                {(!!total && !!total.amount_current_year &&
                    new Intl.NumberFormat("ru-RU").format(
                      (total.amount_current_year / 100).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </Tooltip>
          </td>

          {(isAmount || isClient) && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bdbdbd50",
                  cursor: "pointer",
                }}
              >
                <Tooltip
                  text={"Сумма экономии"}
                  style={{width: "100%", height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {/*{(!!getListTotal(list).startingPriceAmount &&*/}
                    {/*    new Intl.NumberFormat("ru-RU").format(*/}
                    {/*      (getListTotal(list).startingPriceAmount / 100).toFixed(2)*/}
                    {/*    )) ||*/}
                    {/*  "-"}*/}

                    {(!!total && !!total.starting_price_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (total.starting_price_amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#ffd54f50",
                  cursor: "pointer",
                }}
              >
                <Tooltip
                  text={"Сумма по плана закупки"}
                  style={{width: "100%", height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {/*{(!!getListTotal(list).planAmount &&*/}
                    {/*    new Intl.NumberFormat("ru-RU").format(*/}
                    {/*      (getListTotal(list).planAmount / 100).toFixed(2)*/}
                    {/*    )) ||*/}
                    {/*  "-"}*/}

                    {(!!total && !!total.plan_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (total.plan_amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
            </>
          )}

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
              cursor: "pointer",
            }}
          >
          </td>

          {(isCtExecution || isClient) && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#d6cde6",
                  cursor: "pointer",
                }}
              >
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#d6cde6",
                  cursor: "pointer",
                }}
              >
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#d6cde6",
                  cursor: "pointer",
                }}
              >
              </td>
            </>
          )}

          <td
            className="Table__td Table__td_border"
            style={{
              cursor: "pointer",
            }}
          >
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
              cursor: "pointer",
              width: 190,
            }}
          >
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
              cursor: "pointer",
              width: 190,
            }}
          >
          </td>

          {!isClient && (
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bbdefb50",
                cursor: "pointer",
                width: 190,
              }}
            >
            </td>
          )}

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#e6ee9c50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#e6ee9c50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#e6ee9c50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#e6ee9c50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#e6ee9c50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#e6ee9c50",
              cursor: "pointer",
              width: 190,
            }}
          >
          </td>
          {isAddition && (
            <>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                  cursor: "pointer",
                }}
              >
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5cae950",
                  cursor: "pointer",
                }}
              >
              </td>
            </>
          )}
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#ffd54f50",
              cursor: "pointer",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#ffd54f50",
              cursor: "pointer",
            }}
          >
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
            }}
          >
          </td>
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
            }}
          >
          </td>

          {/* Точки контроля */}
          {!!listControlPoints &&
            listControlPoints.map(
              (controlPoint) =>
                (isViewControlGroup(controlGroups, controlPoint) || isClient) && (
                  <td
                    className={
                      "Table__td Table__td_border"
                    }
                    key={"td_control_point_" + controlPoint.uuid}
                    style={{
                      backgroundColor: "#bbdefb50",
                      position: "relative",
                      padding: 4
                    }}
                  >
                  </td>
                )
            )}

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
            }}
          >
            <Tooltip
              text={"Сумма по платежам"}
              style={{width: "100%", height: "100%"}}
            >
              <div className="Td__text Td__text_center">
                {/*{(!!getListTotal(list).amountPayments &&*/}
                {/*    new Intl.NumberFormat("ru-RU").format(*/}
                {/*      (getListTotal(list).amountPayments / 100).toFixed(2)*/}
                {/*    )) ||*/}
                {/*  "-"}*/}

                {(!!total && !!total.amount_payments &&
                    new Intl.NumberFormat("ru-RU").format(
                      (total.amount_payments / 100).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </Tooltip>
          </td>
          {!isClient && (
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#ffd54f50",
              }}
            >
            </td>
          )}
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bdbdbd50",
            }}
          >
            <Tooltip
              text={"Остаток оплаты по контракту"}
              style={{width: "100%", height: "100%"}}
            >
              <div className="Td__text Td__text_center">
                {/*{(!!getListTotal(list).amountAmountPayments &&*/}
                {/*    new Intl.NumberFormat("ru-RU").format(*/}
                {/*      (getListTotal(list).amountAmountPayments / 100).toFixed(2)*/}
                {/*    )) ||*/}
                {/*  "-"}*/}

                {(!!total && !!total.amount_amount_payments &&
                    new Intl.NumberFormat("ru-RU").format(
                      (total.amount_amount_payments / 100).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </Tooltip>
          </td>
        </tr>
      </Fragment>
    )
  );
};
