import React, { Fragment, useState } from "react";
import {
  Search,
  Select,
  Pagination,
  Loading,
  TextSearchMarker,
  getAccountFio,
  ButtonIcon, Tooltip, Icon, getErrorMessageByKey, useProfile, isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import ModalAppealProcessing from "./ModalAppealProcessing";
import ModalAppealRejected from "./ModalAppealRejected";
import ModalAppealExecuted from "./ModalAppealExecuted";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import ModalAppealMessageAdd from "./ModalAppealMessageAdd";
import {Avatar} from "../../../components/Avatar/Avatar";
import {
  ROLE_CLIENT,
} from "../../../config/constants";
import ModalAppealMessageEdit from "./ModalAppealMessageEdit";
import ModalAppealEmployeeViewed from "./ModalAppealEmployeeViewed";
import ModalAppealFileAdd from "./ModalAppealFileAdd";
import ModalAppealDelete from "./ModalAppealDelete";
import ModalAppealFileEdit from "./ModalAppealFileEdit";
import ModalAppealArchive from "./ModalAppealArchive";
import ModalAppealArchiveIn from "./ModalAppealArchiveIn";
import ModalAppealShow from "./ModalAppealShow";

function AppealsPage(props) {
  const {
    profile,

    notification,

    object,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    type,
    setStatus,
    status,

    isUnroll,
    changeUnroll,

    setIsUpdateList,
    isUpdateList,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    selectAppeal,
  } = props;

  const [modalItemPROCESSING, setModalItemPROCESSING] = useState(null);
  const [modalItemREJECTED, setModalItemREJECTED] = useState(null);
  const [modalItemEXECUTED, setModalItemEXECUTED] = useState(null);
  const [modalItemMessageAdd, setModalItemMessageAdd] = useState(null);
  const [modalItemMessageViewed, setModalItemMessageViewed] = useState(null);
  const [modalItemMessageEdit, setModalItemMessageEdit] = useState(null);
  const [modalItemArchive, setModalItemArchive] = useState(null);
  const [modalItemArchiveIn, setModalItemArchiveIn] = useState(null);

  const [modalItemFileAdd, setModalItemFileAdd] = useState(null);
  const [modalItemDelete, setModalItemDelete] = useState(null);
  const [modalItemFileEdit, setModalItemFileEdit] = useState(null);

  const [modalObjectShow, setModalObjectShow] = useState(null);

  const [selectAppealItem, setSelectAppealItem] = useState(null);

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }

  const [modalFileEdit, setModalFileEdit] = useState(null);

  const changeDownload = (item) => {
    axios
      .get("/api/appeal-file/" + item.uuid + "/download", {
        responseType: "arraybuffer",
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Accept: item.mime_type,
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          item.name + item.expansion
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  function calculatePercentage(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const now = moment();

    const totalMilliseconds = end.diff(start);
    const elapsedMilliseconds = now.diff(start);

    const percentage = (elapsedMilliseconds / totalMilliseconds) * 100;

    if (percentage > 100) {
      return 100;
    }

    if (percentage < 0) {
      return 0;
    }

    return Math.floor(percentage); // Округляем до ближайшего целого числа
  }

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }

  const getNameAppeal = (tag) => {
    switch (tag) {
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "Включение в план закупки / план график";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "Согласование договора / дополнительного соглашения";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "Согласование независимых гарантий";

      case "DOCUMENTS_FOR_PURCHASE":
        return "Документы для закупки";

      case "REGISTRATION_AGREEMENT":
        return "Договор на регистрацию";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "Дополнительное соглашение на регистрацию";

      case "REGISTRATION_FORMALIZATION":
        return "Исполнение на регистрацию";

      case "ONE_MORE_QUESTION":
        return "Иной вопрос";
    }
    return null;
  }


  function isEmployeeViewed(item) {
    let isViewed = false

    if (!!item.comments) {
      item.comments.map(comment => {
        if (comment.index > item.employee_viewed_at) {
          isViewed = true
        }
      })
    }

    return isViewed
  }

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  function getAllAppealsCount(counts) {
    let count = 0
    if (!!counts) {
      counts.types.map((t, index) => {
        count += t.count
      })
    }

    return count
  }

  function getSelectAppealsCount(counts) {
    let count = 0
    if (!!counts) {
      counts.type_statuses.map((s, index) => {
        count += s.count
      })
    }

    return count
  }

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            {!!getIconNameAppeal(type) && (
            <span style={{
              marginLeft: 8
            }}>
            <Icon size={40} name={getIconNameAppeal(type)}/>
            </span>
            )}
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              <div>
                Обращения
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
              {!!getNameAppeal(type) && (
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {getNameAppeal(type)}
              </span>
              )}
            </div>
            <span style={{ width: 160, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 50,
                      title: 50,
                    },
                    {
                      value: 75,
                      title: 75,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!!object && (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap"
            }}>


              {!!selectAppeal && (
                <>
                  <div
                    className={styleCard._block + " " + styleCard._block_hover + " " + (status === "null" && styleCard._block_focus)}
                    onClick={() => setStatus("null")}
                    style={{
                      position: "relative",
                      backgroundColor: status === "null" ? "#ffffff" : "#dddddd"
                    }}
                  >
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                      }}
                    >
                      {getSelectAppealsCount(selectAppeal) || "-"}
                    </div>
                    <div className={styleCard._block__title} style={{fontSize: 12}}>
                      Все статусы
                    </div>
                  </div>
                  {selectAppeal.type_statuses.map((s, index) => (
                    <div
                      className={styleCard._block + " " + styleCard._block_hover + " " + (status === s.tag && styleCard._block_focus)}
                      key={'status_' + selectAppeal.tag + "_" + s.tag}
                      // onClick={status !== s.tag ? () => setStatus(s.tag) : () => setStatus("null")}
                      onClick={() => setStatus(s.tag)}
                      style={{
                        position: "relative",
                        backgroundColor: status === s.tag
                          ? "#ffffff"
                          : (s.tag === 'PROCESSING' && s.count > 0 ? "rgba(0, 97, 255, 0.145)"
                            : (s.tag === 'CONSIDERATION' && s.count > 0 ? "rgba(255, 195, 0, 0.314)"
                              : (s.tag === 'RETURNED' && s.count > 0 ? "rgba(255, 0, 0, 0.314)" : "#dddddd")))
                      }}
                    >
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {s.count || "-"}
                      </div>
                      <div className={styleCard._block__title} style={{fontSize: 12}}>
                        {s.tag === 'RETURNED' && 'На контроле'}
                        {s.tag !== 'RETURNED' && s.name}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: -8,
                          left: 0,
                          justifyContent: " flex-end",
                          width: "100%",
                          padding: "0 8px",
                        }}
                      >
                        {s.tag === 'RETURNED' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#f44336",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Есть замечания
                          </div>
                        )}

                        {s.tag === 'CONSIDERATION' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#509d1d",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Новые
                          </div>
                        )}

                        {s.tag === 'PROCESSING' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#0061ff",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            В работе
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {!selectAppeal && (
                <>
                  <div
                    className={styleCard._block + " " + styleCard._block_hover + " " + (status === "null" && styleCard._block_focus)}
                    onClick={() => setStatus("null")}
                    style={{
                      position: "relative",
                      backgroundColor: status === "null" ? "#ffffff" : "#dddddd"
                    }}
                  >
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                      }}
                    >
                      {getAllAppealsCount(object) || "-"}
                    </div>
                    <div className={styleCard._block__title} style={{fontSize: 12}}>
                      Все статусы
                    </div>
                  </div>
                  {object.statuses.map((s, index) => (
                    <div
                      className={styleCard._block + " " + styleCard._block_hover + " " + (status === s.tag && styleCard._block_focus)}
                      key={'status_' + s.tag}
                      // onClick={status !== s.tag ? () => setStatus(s.tag) : () => setStatus("null")}
                      onClick={() => setStatus(s.tag)}
                      style={{
                        position: "relative",
                        backgroundColor: status === s.tag
                          ? "#ffffff"
                          : (s.tag === 'PROCESSING' && s.count > 0 ? "rgba(0, 97, 255, 0.145)"
                            : (s.tag === 'CONSIDERATION' && s.count > 0 ? "rgba(255, 195, 0, 0.314)"
                              : (s.tag === 'RETURNED' && s.count > 0 ? "rgba(255, 0, 0, 0.314)"
                                : (s.tag === 'DRAFT' && s.count > 0 ? "#bbbbbb" : "#dddddd"))))
                      }}
                    >
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {s.count || "-"}
                      </div>
                      <div className={styleCard._block__title} style={{fontSize: 12}}>
                        {s.tag === 'RETURNED' && 'На контроле'}
                        {s.tag !== 'RETURNED' && s.name}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: -8,
                          left: 0,
                          justifyContent: " flex-end",
                          width: "100%",
                          padding: "0 8px",
                        }}
                      >
                        {s.tag === 'RETURNED' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#f44336",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Есть замечания
                          </div>
                        )}

                        {s.tag === 'DRAFT' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#818181",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Не отправлены
                          </div>
                        )}

                        {s.tag === 'CONSIDERATION' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#509d1d",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Новые
                          </div>
                        )}

                        {s.tag === 'PROCESSING' && s.count > 0 && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#0061ff",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            В работе
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}

        <hr className={"Page__hr"}/>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {!!list &&
                list.map((item) => (
                  <Fragment key={"item_" + item.uuid}>
                    <li
                      className={
                        isUnroll(item.uuid) ? "List__item active pointer" : "List__item pointer"
                      }
                      // className={
                      //   !!selectAppealItem && selectAppealItem.uuid === item.uuid ? "List__item active" : "List__item"
                      // }
                      // onClick={() => changeUnroll(item.uuid)}
                      // onContextMenu={event => (event.preventDefault(), setModalObjectShow(item))}
                      onClick={() => (setModalObjectShow(item), setSelectAppealItem(item))}
                      style={{
                        padding: "10px 12px",
                        // backgroundColor:
                        //   isUnroll(item.uuid)
                        //     ? "white"
                        //     : item.appeal_status.tag === "CONSIDERATION"
                        //       ? "#ffc30050"
                        //       : item.appeal_status.tag === "PROCESSING"
                        //         ? calculatePercentage(item.date_filing, item.date_end) === 100
                        //           ? "#ff000025"
                        //           : "#0061ff25"
                        //         : false,
                        position: "relative",
                        flexDirection: "column",
                        alignItems: "stretch",
                        // borderStyle: "solid",
                        // borderWidth: 2,
                        // borderColor:
                        //   item.appeal_status.tag === "CONSIDERATION"
                        //     ? !!selectAppealItem && selectAppealItem.uuid === item.uuid ? "#ffc300"
                        //       : "#ffc30010"
                        //     : item.appeal_status.tag === "PROCESSING"
                        //       ? calculatePercentage(item.date_filing, item.date_end) === 100
                        //         ? "#ff000025"
                        //         : !!selectAppealItem && selectAppealItem.uuid === item.uuid
                        //           ? "#0061ff75"
                        //           :"#0061ff10"
                        //       : "#20202025"
                        border: item.appeal_status.tag === "DRAFT" ? "3px solid #BBBBBB" :
                          item.appeal_status.tag === "RETURNED" ? "3px solid #f44336" :
                            item.appeal_status.tag === "CONSIDERATION" ? "3px solid #ffc107" :
                              item.appeal_status.tag === "PROCESSING" ? "3px solid #0061ff" :
                                false,
                        borderRadius: (item.appeal_status.tag === "DRAFT" ||
                          item.appeal_status.tag === "RETURNED" ||
                          item.appeal_status.tag === "CONSIDERATION" ||
                          item.appeal_status.tag === "PROCESSING") && 9
                      }}
                    >
                      <div className={"Item__wrapper"}>
                        <div
                          className="Item__title"
                        >
                          <div className="Element__title">
                            <Icon size={36} name={getIconNameAppeal(item.appeal_type.tag)}/>
                            <div style={{marginLeft: 8}}>
                              <div
                                style={{
                              fontSize: 17,
                              fontWeight: 600,
                              width: "100%",
                            }}
                          >
                            {(!!item.appeal_type && (
                              <TextSearchMarker
                                text={getNameAppeal(item.appeal_type.tag)}
                                search={searchQuery}
                              />
                            )) || "-"}

                            {!!item.files && (
                              <Tooltip text={'Прикрепления:&nbsp;'+item.files.length} style={{height: 14, marginLeft: 8}}>
                                  <Icon
                                    name={"custom-attach-file"}
                                    color={"#202020"}
                                    size={14}
                                  />
                                </Tooltip>
                            )}
                          </div>

                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                            }}
                          >
                            {(!!item.project &&
                              <div
                                style={{
                                  fontSize: 15,
                                  fontWeight: 500,
                                }}
                              >
                              <TextSearchMarker
                                text={((!!item.project.project_year ? item.project.project_year + " " : "") +
                                  item.project.name +
                                  (!!item.project.law ? " (" + item.project.law.name + ")" : ""))}
                                search={searchQuery}
                              />
                              </div>
                            ) || "-"}


                            {/*{!!item.client && (*/}
                            {/*  <div*/}
                            {/*    style={{*/}
                            {/*      fontSize: 15,*/}
                            {/*      fontWeight: 500,*/}
                            {/*    }}*/}
                            {/*  >*/}
                            {/*    {getAccountFio(item.client)}*/}
                            {/*  </div>*/}
                            {/*)}*/}
                          </div>
                          </div>

                        </div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">

                          {isEmployeeViewed(item) && (
                            <Tooltip
                              text={"Новые&nbsp;сообщения!"}
                              style={{
                                marginRight: 4,
                              }}
                            >
                              <div
                                style={{
                                  width: 28,
                                  height: 28,
                                }}
                              >
                                <Icon name={"custom-comment"} color={"#0061ff"} size={28}/>
                              </div>
                            </Tooltip>
                          )}

                          {!!item.appeal_status && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 700,
                                backgroundColor: "white",
                                display: "inline-block",
                                color: "#202020",
                                padding: "4px 8px 2px",
                                borderRadius: 24,
                                margin: "0 2px",
                              }}
                            >
                              {item.appeal_status.tag === 'RETURNED' && 'На контроле'}
                              {item.appeal_status.tag !== 'RETURNED' && item.appeal_status.name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                      <div style={{
                        margin: "12px 0 0",
                        backgroundColor: "#20202015",
                        padding: "8px 10px",
                        borderRadius: 5
                      }}>

                        {!!item.project && !!item.project.company && (
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 700,
                            }}
                          >
                            {(!!item.project.company.short_name && item.project.company.short_name) || "-"}
                          </div>
                        )}
                        {!!item.client && (
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                            }}
                          >
                            {getAccountFio(item.client)}
                          </div>
                        )}
                      </div>

                      <div className={"Item__wrapper"}
                           style={{
                             marginTop: 4
                           }}>
                        <div
                          className="Item__title"
                          style={{
                            width: "100%",
                            // backgroundColor: "#00000010",
                            borderRadius: 5,
                            border: "1px solid #dbdbdb"
                          }}
                        >
                          <div
                            className="Element__title"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="Form" style={{
                              padding: 6
                            }}>

                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Проект
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.project &&
                                    <TextSearchMarker
                                      text={((!!item.project.project_year ? item.project.project_year + " " : "") +
                                        item.project.name +
                                        (!!item.project.law ? " (" + item.project.law.name + ")" : ""))}
                                      search={searchQuery}
                                    />
                                    ) || "-"}
                                </div>
                              </div>

                              {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                                item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
                                item.appeal_type.tag === "REGISTRATION_AGREEMENT") && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Источник финансирования (КВФО, КЦСР, КВР, КОСГУ)
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.source_funding &&
                                      item.source_funding) || "-"}
                                  </div>
                                </div>
                              )}

                              {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                                item.appeal_type.tag === "REGISTRATION_AGREEMENT") && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Потребность / договор
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.requirement &&
                                      item.requirement) || "-"}
                                  </div>
                                </div>
                              )}

                              {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                                item.appeal_type.tag === "REGISTRATION_AGREEMENT") && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Сумма
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.amount &&
                                      new Intl.NumberFormat("ru-RU").format(
                                        Number(item.amount / 100).toFixed(2)
                                      )) || "-"}
                                  </div>
                                </div>
                              )}

                              {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                                item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE") && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Способ закупки
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.purchase_method &&
                                    item.purchase_method === "EP" ? "ЕП." : item.purchase_method === "KP" ? "КП." : "Не указан") || "-"}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Предполагаемый срок размещения закупки
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.date_placement &&
                                      moment(item.date_placement.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )) || "-"}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Планируемый срок размещения закупки
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.date_placement &&
                                      moment(item.date_placement.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )) || "-"}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Реквизит договора / ДС
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.contract_requisite &&
                                      item.contract_requisite) || "-"}
                                  </div>
                                </div>
                              )}

                              {(item.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" ||
                                item.appeal_type.tag === "ONE_MORE_QUESTION") && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Вопрос
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.question &&
                                      item.question) || "-"}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Дата ДС
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.date_agreement &&
                                      moment(item.date_agreement.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )) || "-"}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Номер договора
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.contract_number &&
                                      item.contract_number)}
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {!!item.contract && (
                                      <div>
                                        <div>
                                          {!!item.project && (
                                            <div>
                                              {!!item.project.name && (
                                                <span
                                                  style={{
                                                    marginRight: 4,
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                    {item.project.project_year}{" "}{item.project.name}
                              </span>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          {item.contract.registry_number && (
                                            <span style={{
                                              marginRight: 6
                                            }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                                              {item.contract.registry_number + " "}
                              </span>
                                          )}
                                          {item.contract.contract_number}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Документ (акт / платёж)
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.document_type &&
                                    item.document_type === "ACT" ? "Акт" : item.document_type === "PAYMENT" ? "Платёж" : "Не указан") || "-"}
                                  </div>
                                </div>
                              )}

                              {item.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Дата документа
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.document_date &&
                                      moment(item.document_date.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )) || "-"}
                                  </div>
                                </div>
                              )}

                              {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                                item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
                                item.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
                                item.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES" ||
                                item.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION") && (
                                <div className="Form__field" style={{
                                  marginBottom: 5,
                                }}>
                                  <div className={"Field__label"} style={{
                                    marginBottom: 0,
                                    fontSize: 11,
                                  }}>Примечание
                                  </div>
                                  <div className={"Field__text"} style={{
                                    fontSize: 13,
                                  }}>
                                    {(!!item.note && item.note) || "-"}
                                  </div>
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>


                      <div className={"Item__wrapper"}>
                        <div
                          className="Item__title"
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            className="Element__title"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                opacity: 0.75,
                                marginTop: 4,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                {!!item.date_filing && (
                                  <div
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 600,
                                      backgroundColor: "#20202015",
                                      display: "inline-block",
                                      color: "#202020",
                                      padding: "3px 6px 3px",
                                      borderRadius: 3,
                                      margin: "0 2px 0 0",
                                    }}
                                  >
                                    {"Дата подачи: "}
                                    {!!item.date_filing &&
                                      moment(item.date_filing.substring(0, 19)).format(
                                        "DD.MM.YYYY HH:mm"
                                      )}
                                  </div>
                                )}

                                {!!item.date_end && item.appeal_status.tag === "PROCESSING" && (
                                  <div
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 600,
                                      backgroundColor: "#20202015",
                                      display: "inline-block",
                                      color: "#202020",
                                      padding: "3px 6px 3px",
                                      borderRadius: 3,
                                      margin: "0 2px 0 0",
                                    }}
                                  >
                                    {"В работе до: "}
                                    {!!item.date_end &&
                                      moment(item.date_end.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )}
                                  </div>
                                )}
                                {!!item.date_closing && item.appeal_status.tag === "EXECUTED" && (
                                  <div
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 600,
                                      backgroundColor: "#20202015",
                                      display: "inline-block",
                                      color: "#202020",
                                      padding: "3px 6px 3px",
                                      borderRadius: 3,
                                      margin: "0 2px 0 0",
                                    }}
                                  >
                                    {"Дата завершения: "}
                                    {!!item.date_closing &&
                                      moment(item.date_closing.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>

                            {!!item.date_filing && !!item.date_end && item.appeal_status.tag === "PROCESSING" && (
                              <div
                                style={{
                                  height: 4,
                                  width: "100%",
                                  backgroundColor: "#2020200f",
                                  borderRadius: 2,
                                  marginTop: 4,
                                }}
                              >
                                <div
                                  style={{
                                    height: 4,
                                    width: calculatePercentage(item.date_filing, item.date_end) + "%",
                                    backgroundColor:
                                      calculatePercentage(item.date_filing, item.date_end) === 100
                                        ? "#ff000095"
                                        : "#0061ff75",
                                    borderRadius: 2,
                                  }}
                                ></div>
                              </div>
                            )}

                            {!!item.executor && (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: 4,
                                }}
                              >
                                <div style={{
                                  padding: "8px",
                                  backgroundColor: "#20202010",
                                  borderRadius: 7,
                                  textAlign: "left"
                                }}>

                                  <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}>
                                    <div style={{
                                      marginRight: 8,
                                      height: 36
                                    }}>
                                      {!!item.executor.avatar && isMD5Hash(item.executor.avatar) && (
                                        <img height={36} width={36} style={{
                                          borderRadius: 36,
                                        }} src={ROOT_URL + "/api/account/avatar/" + item.executor.avatar}/>
                                      )}
                                      {!!item.executor.avatar && !isMD5Hash(item.executor.avatar) && (
                                        <img height={36} width={36} style={{
                                          borderRadius: 36,
                                        }} src={"data:image/jpeg;base64, " + item.executor.avatar}/>
                                      )}

                                      {!item.executor.avatar && (
                                        <Avatar size={36} value={getAccountFio(item.executor)}/>
                                      )}
                                    </div>
                                    <div>
                                      <div style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                      }}>
                                        Исполнитель
                                      </div>
                                      <div style={{
                                        fontSize: 15,
                                        fontWeight: 700,
                                      }}>
                                        {getAccountFio(item.executor)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "absolute",
                              top: -8,
                              left: 0,
                              justifyContent: " flex-end",
                              width: "100%",
                              padding: "0 8px",
                            }}
                          >
                            {item.appeal_status.tag === 'RETURNED' && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#f44336",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                Есть замечания
                              </div>
                            )}

                            {item.appeal_status.tag === 'DRAFT' && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#818181",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                Не отправлено
                              </div>
                            )}

                            {item.appeal_status.tag === 'CONSIDERATION' && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#509d1d",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                Новое
                              </div>
                            )}

                            {item.appeal_status.tag === 'PROCESSING' && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#0061ff",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                В работе
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="Item__settings">
                          <div className="Element__datetime">
                          </div>
                        </div>
                      </div>
                    </li>

                    {false && isUnroll(item.uuid) && (
                      <li className="List__content">
                        <div className="Content__header">
                          <div className="Header__title">

                            {!item.employee_archived_at && (
                              <>
                                {(item.appeal_status.tag === "CONSIDERATION" ||
                                  item.appeal_status.tag === "REJECTED" ||
                                  item.appeal_status.tag === "EXECUTED") && (
                                  <>
                                    <div
                                      className="Button__text Button__text_right"
                                      onClick={() => setModalItemPROCESSING(item)}
                                    >
                                      В работу
                                    </div>
                                  </>
                                )}

                                {item.appeal_status.tag === "PROCESSING" && (
                                  <>
                                    <div
                                      className="Button__text Button__text_right"
                                      onClick={() => setModalItemEXECUTED(item)}
                                    >
                                      Завершить
                                    </div>
                                  </>
                                )}

                                {(item.appeal_status.tag === "PROCESSING" ||
                                  item.appeal_status.tag === "CONSIDERATION") && (
                                  <>
                                    <div
                                      className="Button__text Button__text_right"
                                      onClick={() => setModalItemREJECTED(item)}
                                    >
                                      Отклонить
                                    </div>
                                  </>
                                )}

                              </>
                            )}

                            {!item.employee_archived_at && (
                              <>
                                |

                                <div
                                  className="Button__text Button__text_left Button__text_right"
                                  onClick={() => setModalItemFileAdd(item)}
                                >
                                  Прикрепить файлы
                                </div>
                              </>
                            )}


                            {!item.employee_archived_at && (
                              <>
                                |
                                <div
                                  className="Button__text Button__text_left Button__text_right"
                                  onClick={() => setModalItemMessageAdd(item)}
                                >
                                  Ответить
                                </div>
                              </>
                            )}


                            {isEmployeeViewed(item) && (
                              <div
                                className="Button__text Button__text_right"
                                onClick={() => setModalItemMessageViewed(item)}
                              >
                                Прочитано
                              </div>
                            )}

                            {(!item.employee_archived_at && !!item.appeal_status && (item.appeal_status.tag === "REJECTED" || item.appeal_status.tag === "EXECUTED")) && (
                              <> |
                              <div
                                className="Button__text Button__text_left Button__text_right"
                                onClick={() => setModalItemArchive(item)}
                                style={{
                                  color: "#0061ff"
                                }}
                              >
                                В архив
                              </div>
                              </>
                            )}

                            {!!item.employee_archived_at && (
                              <>
                                <div
                                  className="Button__text Button__text_right"
                                  onClick={() => setModalItemArchiveIn(item)}
                                  style={{
                                    color: "#0061ff"
                                  }}
                                >
                                  Восстановить из архива
                                </div>
                              </>
                            )}
                          </div>
                          <div className="Header__settings">
                            <div
                              className="Button__text Button__text_left"
                              onClick={() => setModalItemDelete(item)}
                              style={{
                                color: "#cf2f00"
                              }}
                            >
                              Удалить обращение
                            </div>
                          </div>
                        </div>




                        <div
                          className={
                            styleContent._block + " " + styleContent._block_bottom
                          }
                        >
                          <div className={styleContent._block__title}>Информация</div>
                          <div className={styleContent._block__content}>
                            <table className="Table">
                              <tbody>
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Проект
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {(!!item.project && ((!!item.project.project_year ? item.project.project_year + " " : "") +
                                        item.project.name +
                                        (!!item.project.law ? " (" + item.project.law.name + ")" : ""))) || "-"}
                                    </div>
                                  </td>
                                </tr>

                              {!!item.source_funding && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Источник финансирования
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.source_funding}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.requirement && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Требование
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.requirement}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.amount && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Сумма
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {new Intl.NumberFormat("ru-RU").format(
                                        Number(item.amount / 100).toFixed(2)
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.purchase_method && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Способ закупки
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.purchase_method}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.date_placement && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Дата размещения
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {moment(item.date_placement.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.date_agreement && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Дата соглашения
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {moment(item.date_agreement.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.contract_requisite && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Реквизиты договора
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.contract_requisite}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.question && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Вопрос
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.question}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.note && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Примечание
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.note}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.contract_number && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Номер договора
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.contract_number}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.document_type && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Тип документа
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.document_type}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.document_date && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Дата документа
                                    </div>
                                  </td>
                                  <td className="Table__td" style={{
                                    width: "100%"
                                  }}>
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {moment(item.document_date.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>






                        {!!item.files && (
                          <div
                            className={
                              styleContent._block + " " + styleContent._block_bottom
                            }
                          >
                            <div className={styleContent._block__title}>Документы</div>
                            <div className={styleContent._block__content}>
                          <div className={styleContent._block}>
                            <div className={styleContent._block__content}>
                              {item.files.map((file) => (
                                <div
                                  className={styleCard._block + " " + styleCard._block_hover}
                                  key={item.uuid + ":" + file.uuid}
                                  onClick={(event) => (event.preventDefault(), setModalItemFileEdit(file))}
                                  style={{
                                    minWidth: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  {!!file.expansion && (
                                    <span
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        textTransform: "uppercase",
                                        fontSize: 10,
                                        fontWeight: 900,
                                        borderRadius: 3,
                                        color: "white",
                                        backgroundColor: getColorFile(file.expansion.slice(1)),
                                        marginRight: 8,
                                        padding: "2px 4px"
                                      }}>
                                    {file.expansion.slice(1)}
                                  </span>
                                  )}
                                  <span className={styleCard._block__title} style={{
                                    fontSize: 15
                                  }}>
                                  {file.name}
                                </span>
                                </div>
                              ))}
                            </div>
                          </div>
                            </div>
                          </div>
                        )}






                        <hr className={"Page__hr"} />

                        {!!item.comments && (
                        <div
                          className={
                            styleContent._block + " " + styleContent._block_bottom
                          }
                        >
                          <div className={styleContent._block__title}>Сообщения</div>
                          <div className={styleContent._block__content}
                          style={{
                            flexDirection: "column",
                            flexWrap: "nowrap",
                          }}>
                            {item.comments.map((comment) => (
                              <div key={"comment_"+comment.uuid}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    maxWidth: "33%",
                                    minWidth: 720,
                                    justifyContent: profile.uuid === comment.author.uuid && "flex-end"
                                  }}>
                                <div
                                  style={{
                                display: "inline-block",
                                    // width: "100%",
                                    maxWidth: 720,
                                backgroundColor: "#ffffff",
                                marginBottom: 4,
                                    borderRadius: 5,

                                    border: "1px solid #dbdbdb75",
                                    margin: "0 0 8px 0",
                              }}>

                                <p style={{
                                  fontSize: 17,
                                  fontWeight: 400,
                                  padding: "8px 10px",
                                  borderRadius: "5px 5px 0 0",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-line",
                                }}>
                                  {comment.comment}
                                </p>
                                <div style={{
                                  padding: "4px 10px 4px 8px",
                                  backgroundColor: comment.index > item.employee_viewed_at ?
                                    "#0061ff33"
                                    : profile.uuid === comment.author.uuid ? "white" : "#20202025",
                                  borderRadius: "0 0 5px 5px"
                                }}>
                                  <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%"
                                  }}>
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                  <div style={{
                                    marginRight: 8
                                  }}>
                                    {!!comment.author.avatar && isMD5Hash(comment.author.avatar) && (
                                      <img height={36} width={36} style={{
                                        borderRadius: 36,
                                      }}  src={ROOT_URL + "/api/account/avatar/" + comment.author.avatar}/>
                                    )}
                                    {!!comment.author.avatar && !isMD5Hash(comment.author.avatar) && (
                                      <img height={36} width={36} style={{
                                        borderRadius: 36,
                                      }}  src={"data:image/jpeg;base64, "+ comment.author.avatar}/>
                                    )}

                                    {!comment.author.avatar && (
                                      <Avatar size={36} value={getAccountFio(comment.author)} />
                                    )}
                                  </div>
                                  <div>
                                    <div style={{
                                      fontSize: 15,
                                      fontWeight: 600
                                    }}>{getAccountFio(comment.author)}</div>
                                    <div style={{
                                      marginTop: 2,
                                      fontSize: 13,
                                      fontWeight: 400
                                    }}>{moment.unix(comment.index).format(
                                      "HH:mm DD.MM.YYYY"
                                    )}</div>
                                  </div>
                                    </div>


                                    <div>
                                      {!isProfileRoleTypeTags(profile, [
                                        ROLE_CLIENT,
                                      ]) && !!comment.author && profile.uuid === comment.author.uuid && (
                                        <ButtonIcon
                                          tooltipText={"Редактировать"}
                                          iconName={"custom-edit"}
                                          iconColor={"#202020"}
                                          size={36}
                                          style={{ marginLeft: 12 }}
                                          onClick={() => setModalItemMessageEdit(comment)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                </div>

                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                          )}

                      </li>
                    )}
                  </Fragment>
                ))}
            </ul>
          </div>
        )}

        {!!modalItemPROCESSING && (
          <ModalAppealProcessing
            object={modalItemPROCESSING}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemPROCESSING(null)}
          />
        )}

        {!!modalItemREJECTED && (
          <ModalAppealRejected
            object={modalItemREJECTED}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemREJECTED(null)}
          />
        )}

        {!!modalItemEXECUTED && (
          <ModalAppealExecuted
            object={modalItemEXECUTED}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEXECUTED(null)}
          />
        )}

        {!!modalItemMessageViewed && (
          <ModalAppealEmployeeViewed
            object={modalItemMessageViewed}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemMessageViewed(null)}
          />
        )}

        {!!modalItemFileAdd && (
          <ModalAppealFileAdd
            object={modalItemFileAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemFileAdd(null)}
          />
        )}

        {!!modalItemFileEdit && (
          <ModalAppealFileEdit
            file={modalItemFileEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemFileEdit(null)}
          />
        )}


        {!!modalItemDelete && (
          <ModalAppealDelete
            object={modalItemDelete}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemDelete(null)}
          />
        )}


        {!!modalItemMessageAdd && (
          <ModalAppealMessageAdd
            object={modalItemMessageAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemMessageAdd(null)}
          />
        )}

        {!!modalItemMessageEdit && (
          <ModalAppealMessageEdit
            object={modalItemMessageEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemMessageEdit(null)}
          />
        )}

        {!!modalItemArchive && (
          <ModalAppealArchive
            object={modalItemArchive}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemArchive(null)}
          />
        )}

        {!!modalItemArchiveIn && (
          <ModalAppealArchiveIn
            object={modalItemArchiveIn}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemArchiveIn(null)}
          />
        )}

        {!!modalObjectShow && (
          <ModalAppealShow
            object={modalObjectShow}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectShow(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default AppealsPage;
