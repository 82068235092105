import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  useList, Select, Textarea, jsonFormatText, Search, TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalContractItemSelect(props) {
  const getParams = () => {
    let params = null;
    params = {
      ...params,
      view: 'current',
    };
    if (!!props.selectProjectUuid && props.selectProjectUuid !== "null") {
      params = {
        ...params,
        project: props.selectProjectUuid,
      };
    }

    return params
  }

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams
  } = useList({
    apiUrl: "/api/client/projects/contracts",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 250,
    isSearch: true,
    urlParams: getParams()
  });

  const changeAdd = (item) => {
        props.successfulRequest(item);
        props.handleClose();
  };

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Выбор договора
          </div>

          <div className="Form__field">
            <Search
              reference={inputSearchText}
              type="text"
              label={"Поиск"}
              isFixed={true}
              onChange={() => onChangeSearchText()}
            />
          </div>

          <div className="Form__field Form__field_last"
          style={{
            maxHeight: 250,
            overflowY: "auto"
          }}>
            {list.map((item, index) => (
              <div key={"item_" + index} onClick={() => changeAdd(item)} style={{
                padding: "4px 8px",
                border: "1px solid #dbdbdb",
                cursor: "pointer",
                marginBottom: 6,
                borderRadius: 5,
              }}>
                <div>
                  <div>
                    {!!item.project && (
                      <div>
                        {!!item.project.name && (
                          <span
                            style={{
                              marginRight: 4,
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                                    {item.project.project_year}{" "}{item.project.name}
                              </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {item.registry_number && (
                      <span style={{
                        marginRight: 6
                      }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                                <TextSearchMarker
                                  text={item.registry_number + " "}
                                  search={searchQuery}
                                />
                              </span>
                    )}
                    <TextSearchMarker
                      text={item.contract_number}
                      search={searchQuery}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {!!props.item && (
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => (props.successfulRequest(null), props.handleClose())}
              >
                Удалить выбранный
              </div>
            </div>
          )}

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalContractItemSelect;
