import React from "react";
import ModalHomeFilter from "./ModalHomeFilter";
import {ButtonIcon, getAccountFio, Loading} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";

export const HomePage = (props) => {
  const {
    object,
    setObject,

    isUpdateObject,
    setIsUpdateObject,

    isModalFilter,
    setIsModalFilter,
    filterEmployees,
    setFilterEmployees,
    listEmployees,
    getEmployeeByFilter,
    deleteFilter,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Главная
            <ButtonIcon
              tooltipText={"Фильтры&nbsp;поиска"}
              iconName={"custom-search-filter"}
              iconColor={"#20202075"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalFilter(!isModalFilter)}
            />
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setObject(null), setIsUpdateObject(!isUpdateObject))}
            />
          </div>
        </div>

        {((!!filterEmployees &&
          filterEmployees.length > 0 &&
          !!listEmployees)) && (
          <div
            style={{
              marginBottom: 12,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!!filterEmployees &&
                !!listEmployees &&
                filterEmployees.map((filter) => (
                  <div
                    key={"filter1_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Сотрудник
                    </span>
                    <span>
                      {!!getEmployeeByFilter(listEmployees, filter) &&
                        getAccountFio(
                          getEmployeeByFilter(listEmployees, filter).account
                        )}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterEmployees,
                          filter,
                          setFilterEmployees
                        )
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        )}


        {!object && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}


        {!!object && (
          <div className="Page__content">
            <ul className="List">
              <div className="Page__content">
                {!!object.count_appeals && (
                  <div
                    className={styleContent._block + " " + styleContent._block_bottom}
                  >
                    <div className={styleContent._block__title}>
                      Обращения
                    </div>
                    <div className={styleContent._block__content}>
                      {!!object.count_appeals && object.count_appeals.statuses.map((s, index) => (
                        s.tag === 'CONSIDERATION' && (
                          <div className={styleCard._block} style={{
                            backgroundColor: s.tag === 'CONSIDERATION' && s.count > 0 && "rgba(255, 195, 0, 0.314)"
                          }}>
                            <div
                              className={styleCard._block__number}
                            >

                              {s.count}

                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              Новые
                            </div>
                          </div>
                        )))}

                      {!!object.count_appeals && object.count_appeals.statuses.map((s, index) => (
                        s.tag === 'PROCESSING' && (
                          <div className={styleCard._block} style={{
                            backgroundColor: s.tag === 'PROCESSING' && s.count > 0 && "rgba(0, 97, 255, 0.145)"
                          }}>
                            <div
                              className={styleCard._block__number}
                            >
                              {s.count}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              В работе
                            </div>
                          </div>
                        )))}

                      {!!object.count_appeals && object.count_appeals.statuses.map((s, index) => (
                        s.tag === 'RETURNED' && (
                          <div className={styleCard._block} style={{
                            backgroundColor: s.tag === 'RETURNED' && s.count > 0 && "rgba(255, 0, 0, 0.314)"
                          }}>
                            <div
                              className={styleCard._block__number}
                            >
                              {s.count}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              На контроле
                            </div>
                          </div>
                        )))}

                      {/*<div style={{*/}
                      {/*  height: 88,*/}
                      {/*  width: 2,*/}
                      {/*  backgroundColor: "#20202010",*/}
                      {/*  borderRadius: 4,*/}
                      {/*  marginRight: 8,*/}
                      {/*  marginBottom: 8,*/}
                      {/*  textAlign: "right"*/}
                      {/*}}>*/}
                      {/*</div>*/}

                    </div>
                  </div>
                )}

                {!!object.count_tasks && (
                  <div
                    className={styleContent._block + " " + styleContent._block_bottom}
                  >
                    <div className={styleContent._block__title}>
                      Задачи на контроль
                    </div>
                    <div className={styleContent._block__content}>

                      {/*<div style={{*/}
                      {/*  height: 88,*/}
                      {/*  width: 2,*/}
                      {/*  backgroundColor: "#20202010",*/}
                      {/*  borderRadius: 4,*/}
                      {/*  marginRight: 8,*/}
                      {/*  marginBottom: 8,*/}
                      {/*  textAlign: "right"*/}
                      {/*}}>*/}
                      {/*</div>*/}

                      {!!object.count_tasks && (
                        !!object.count_tasks.task_general_projects && (
                          <div className={styleCard._block} style={{
                            backgroundColor: object.count_tasks.task_general_projects.count_day > 0 && "rgba(0, 97, 255, 0.145)"
                          }}>
                            <div
                              className={styleCard._block__number}
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {object.count_tasks.task_general_projects.count_day}

                              {object.count_tasks.task_general_projects.count_overdue > 0 && (
                                <span
                                  style={{
                                    color: "white",
                                    backgroundColor: "#cf2f00",
                                    border: "2px solid #cf2f00",
                                    marginLeft: "12px",
                                    flexDirection: "column",
                                    fontSize: "15px",
                                    borderRadius: "24px",
                                    height: "28px",
                                    padding: "1px 8px 2px",
                                    fontWeight: 900,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  {object.count_tasks.task_general_projects.count_overdue}
                                </span>
                              )}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              Общие
                            </div>
                          </div>
                        ))}

                      {!!object.count_tasks &&  (
                        !!object.count_tasks.task_projects && (
                          <div className={styleCard._block} style={{
                            backgroundColor: object.count_tasks.task_projects.count_day > 0 && "rgba(0, 97, 255, 0.145)"
                          }}>
                            <div
                              className={styleCard._block__number}
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {object.count_tasks.task_projects.count_day}

                              {object.count_tasks.task_projects.count_overdue > 0 && (
                                <span
                                  style={{
                                    color: "white",
                                    backgroundColor: "#cf2f00",
                                    border: "2px solid #cf2f00",
                                    marginLeft: "12px",
                                    flexDirection: "column",
                                    fontSize: "15px",
                                    borderRadius: "24px",
                                    height: "28px",
                                    padding: "1px 8px 2px",
                                    fontWeight: 900,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  {object.count_tasks.task_projects.count_overdue}
                                </span>
                              )}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              По проектам
                            </div>
                          </div>
                        ))}

                      {!!object.count_tasks && (
                        !!object.count_tasks.task_control_points && (
                          <div className={styleCard._block} style={{
                            backgroundColor: object.count_tasks.task_control_points.count_day > 0 && "rgba(0, 97, 255, 0.145)"
                          }}>
                            <div
                              className={styleCard._block__number}
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >

                              {object.count_tasks.task_control_points.count_day}

                              {object.count_tasks.task_control_points.count_overdue > 0 && (
                                <span
                                  style={{
                                    color: "white",
                                    backgroundColor: "#cf2f00",
                                    border: "2px solid #cf2f00",
                                    marginLeft: "12px",
                                    flexDirection: "column",
                                    fontSize: "15px",
                                    borderRadius: "24px",
                                    height: "28px",
                                    padding: "1px 8px 2px",
                                    fontWeight: 900,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  {object.count_tasks.task_control_points.count_overdue}
                                </span>
                              )}

                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              Контрольные точки
                            </div>
                          </div>
                        ))}

                      {/*{!!object.count_tasks && (*/}
                      {/*  !!object.count_tasks.task_executions && (*/}
                      {/*    <div className={styleCard._block} style={{*/}
                      {/*      backgroundColor: object.count_tasks.task_executions.count_day > 0 && "rgba(0, 97, 255, 0.145)"*/}
                      {/*    }}>*/}
                      {/*      <div*/}
                      {/*        className={styleCard._block__number}*/}
                      {/*        style={{*/}
                      {/*          display: "flex",*/}
                      {/*          alignItems: "center"*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        {object.count_tasks.task_executions.count_day}*/}

                      {/*        {object.count_tasks.task_executions.count_overdue > 0 && (*/}
                      {/*          <span*/}
                      {/*            style={{*/}
                      {/*              color: "white",*/}
                      {/*              backgroundColor: "#cf2f00",*/}
                      {/*              border: "2px solid #cf2f00",*/}
                      {/*              marginLeft: "12px",*/}
                      {/*              flexDirection: "column",*/}
                      {/*              fontSize: "15px",*/}
                      {/*              borderRadius: "24px",*/}
                      {/*              height: "28px",*/}
                      {/*              padding: "1px 8px 2px",*/}
                      {/*              fontWeight: 900,*/}
                      {/*              display: "flex",*/}
                      {/*              alignItems: "center",*/}
                      {/*              justifyContent: "center"*/}
                      {/*            }}*/}
                      {/*          >*/}
                      {/*            {object.count_tasks.task_executions.count_overdue}*/}
                      {/*          </span>*/}
                      {/*        )}*/}
                      {/*      </div>*/}
                      {/*      <div*/}
                      {/*        className={styleCard._block__title}*/}
                      {/*      >*/}
                      {/*        Исполнение*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  ))}*/}

                    </div>
                  </div>
                )}

                {/* ------------------------------------------------------------------------------------------ */}

                {!!object.count_tasks && filterEmployees.length > 0 && (
                  <div
                    className={styleContent._block + " " + styleContent._block_bottom}
                  >
                    <div className={styleContent._block__title}>
                      Задачи персональные
                    </div>
                    <div className={styleContent._block__content}>

                      {/*<div style={{*/}
                      {/*  height: 88,*/}
                      {/*  width: 2,*/}
                      {/*  backgroundColor: "#20202010",*/}
                      {/*  borderRadius: 4,*/}
                      {/*  marginRight: 8,*/}
                      {/*  marginBottom: 8,*/}
                      {/*  textAlign: "right"*/}
                      {/*}}>*/}
                      {/*</div>*/}

                      {!!object.count_tasks &&  (
                        !!object.count_tasks.task_person_projects && (
                          <div className={styleCard._block} style={{
                            backgroundColor: object.count_tasks.task_person_projects.count_day > 0 && "rgba(0, 97, 255, 0.145)"
                          }}>
                            <div
                              className={styleCard._block__number}
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {object.count_tasks.task_person_projects.count_day}

                              {object.count_tasks.task_person_projects.count_overdue > 0 && (
                                <span
                                  style={{
                                    color: "white",
                                    backgroundColor: "#cf2f00",
                                    border: "2px solid #cf2f00",
                                    marginLeft: "12px",
                                    flexDirection: "column",
                                    fontSize: "15px",
                                    borderRadius: "24px",
                                    height: "28px",
                                    padding: "1px 8px 2px",
                                    fontWeight: 900,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  {object.count_tasks.task_person_projects.count_overdue}
                                </span>
                              )}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              По проектам
                            </div>
                          </div>
                        ))}

                      {!!object.count_tasks && (
                        !!object.count_tasks.task_person_control_points && (
                          <div className={styleCard._block} style={{
                            backgroundColor: object.count_tasks.task_person_control_points.count_day > 0 && "rgba(0, 97, 255, 0.145)"
                          }}>
                            <div
                              className={styleCard._block__number}
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >

                              {object.count_tasks.task_person_control_points.count_day}

                              {object.count_tasks.task_person_control_points.count_overdue > 0 && (
                                <span
                                  style={{
                                    color: "white",
                                    backgroundColor: "#cf2f00",
                                    border: "2px solid #cf2f00",
                                    marginLeft: "12px",
                                    flexDirection: "column",
                                    fontSize: "15px",
                                    borderRadius: "24px",
                                    height: "28px",
                                    padding: "1px 8px 2px",
                                    fontWeight: 900,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  {object.count_tasks.task_person_control_points.count_overdue}
                                </span>
                              )}

                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              Контрольные точки
                            </div>
                          </div>
                        ))}

                      {/*{!!object.count_tasks && (*/}
                      {/*  !!object.count_tasks.task_person_executions && (*/}
                      {/*    <div className={styleCard._block} style={{*/}
                      {/*      backgroundColor: object.count_tasks.task_person_executions.count_day > 0 && "rgba(0, 97, 255, 0.145)"*/}
                      {/*    }}>*/}
                      {/*      <div*/}
                      {/*        className={styleCard._block__number}*/}
                      {/*        style={{*/}
                      {/*          display: "flex",*/}
                      {/*          alignItems: "center"*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        {object.count_tasks.task_person_executions.count_day}*/}

                      {/*        {object.count_tasks.task_person_executions.count_overdue > 0 && (*/}
                      {/*          <span*/}
                      {/*            style={{*/}
                      {/*              color: "white",*/}
                      {/*              backgroundColor: "#cf2f00",*/}
                      {/*              border: "2px solid #cf2f00",*/}
                      {/*              marginLeft: "12px",*/}
                      {/*              flexDirection: "column",*/}
                      {/*              fontSize: "15px",*/}
                      {/*              borderRadius: "24px",*/}
                      {/*              height: "28px",*/}
                      {/*              padding: "1px 8px 2px",*/}
                      {/*              fontWeight: 900,*/}
                      {/*              display: "flex",*/}
                      {/*              alignItems: "center",*/}
                      {/*              justifyContent: "center"*/}
                      {/*            }}*/}
                      {/*          >*/}
                      {/*            {object.count_tasks.task_person_executions.count_overdue}*/}
                      {/*          </span>*/}
                      {/*        )}*/}
                      {/*      </div>*/}
                      {/*      <div*/}
                      {/*        className={styleCard._block__title}*/}
                      {/*      >*/}
                      {/*        Исполнение*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  ))}*/}

                    </div>
                  </div>
                )}
              </div>
            </ul>
          </div>
        )}

        {!!isModalFilter && (
          <ModalHomeFilter
            useSearchFilter={{
              filterEmployees,
              setFilterEmployees,
            }}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setIsModalFilter(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
