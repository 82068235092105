import React, {Fragment} from "react";
import {TextSearchMarker, Tooltip,} from "@vokymlak/kabinet-ui";
import moment from "moment";


export const ContractPfhdList = (props) => {
  const {
    list,
    object,

    setModalSpecificationEdit,

    selectSpecification,
    setSelectSpecification,

    searchQuery,
  } = props;

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover " +
              (!!selectSpecification && selectSpecification === item.uuid
                ? "Table__tr_focus"
                : "")
            }
            key={"item_" + item.uuid}
            onClick={() => (
              setModalSpecificationEdit(item), setSelectSpecification(item.uuid)
            )}
            style={{
              cursor: "pointer",
            }}
          >
            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Обновлено"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {!!item.updated_at
                    ? moment(item.updated_at).format("DD.MM.YYYY")
                    : moment(item.created_at).format("DD.MM.YYYY")}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Реестровый номер"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.registry_number && (
                      <TextSearchMarker
                        text={item.registry_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
                <div className="Td__text Td__text_center" style={{
                  fontSize: 10,
                  fontWeight: 400
                }}>
                  {(!!item.ris_id && item.ris_id) ||
                    "-"} / {(!!item.ris_entity_id && item.ris_entity_id) ||
                  "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Реестровый номер КБК"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center"
                     style={{
                       color: !!item.ris_kbk_id && ((!!item.project_pfhd_id && "#0064ff") || "#ff0000")
                     }}>
                  {(!!item.ris_kbk_id && (
                      <TextSearchMarker
                        text={item.ris_kbk_id}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Источник финансирования"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.financing_type_name && (
                      <TextSearchMarker
                        text={item.financing_type_name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Тип финансирования"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.financing_kbk_type_name && (
                      <TextSearchMarker
                        text={item.financing_kbk_type_name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Сумма"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.amount / 100).toFixed(2)
                      )) ||
                    "0,00"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Год"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.year && (
                      <TextSearchMarker
                        text={item.year}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
