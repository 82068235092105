import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import moment from "moment";
import ModalProjectAmountEdit from "./ModalProjectAmountEdit";

export const CalcEfficiencyFactorTable = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  function getP02(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) > 50_000_000_00
        ? 50_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) < 50_000_000_00
          ? Number((calculations.sgoz_amount) * 0.1)
          : 0
    )
  }

  function getP03(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) > 30_000_000_00
        ? 30_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) < 30_000_000_00
          ? Number((calculations.sgoz_amount) * 0.5)
          : 0
    )
  }

  function getP4(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) > 50_000_000_00
        ? 50_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) < 50_000_000_00
          ? Number((calculations.sgoz_amount) * 0.1)
          : 0
    )
  }

  function getP5(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) > 30_000_000_00
        ? 30_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) < 30_000_000_00
          ? Number((calculations.sgoz_amount) * 0.5)
          : 0
    )
  }

  function getPlanFz(object, calculations) {
    let planFz = calculations.sgoz_amount -
      ((object.law.tag === "44" && getP4(calculations)) || 0) -
      ((object.law.tag === "44" && getP5(calculations)) || 0) -
      ((object.law.tag === "223" && getP02(calculations)) || 0) -
      ((object.law.tag === "223" && getP03(calculations)) || 0) -
      calculations.amount_current_year_e_shop

    if (!!calculations.ep_ways) {
      calculations.ep_ways.forEach(epWay => {
        if (!!epWay.plan_amount) {
          planFz -= epWay.plan_amount
        } else {
          planFz -= epWay.amount
        }
      });
    }

    return planFz;
  }

  function getPlanDate(calculations) {
    let planDate = calculations.sgoz_amount

    if (!!calculations.ep_ways) {
      calculations.ep_ways.forEach(epWay => {
        if (!!epWay.plan_amount) {
          planDate -= epWay.plan_amount
        }
      });
    }

    return planDate * .7;
  }

  function getPlanFact(calculations) {
    let planFact = calculations.sgoz_amount -
      calculations.amount_current_year_e_shop

    if (!!calculations.ep_ways) {
      calculations.ep_ways.forEach(epWay => {
        if (!!epWay.amount) {
          planFact -= epWay.amount
        }
      });
    }

    return planFact * .7;
  }

  function getPlanPercent(calculations) {
    return calculations.compete_procurement_amount_current_year * 100 / getPlanFact(calculations);
  }

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>
          Коэффициент эффективности
        </div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#e9530d75",
                }}
              >
                <div className="Th__title">
                  План
                  <Tooltip
                    text={
                      'Редактировать'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#e9530d75",
                }}
              >
                <div className="Th__title">
                  План по ФЗ
                  {/*<Tooltip*/}
                  {/*  text={*/}
                  {/*    'Редактировать'*/}
                  {/*  }*/}
                  {/*  style={{marginLeft: 4, height: 20}}*/}
                  {/*>*/}
                  {/*  <Icon name={"custom-edit"} color={"#202020"} size={20}/>*/}
                  {/*</Tooltip>*/}
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#e9530d75",
                }}
              >
                <div className="Th__title">
                  План на {moment().format("DD.MM.YYYY")}
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Цена&nbsp;договора"&nbsp;(КП)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#e9530d75",
                }}
              >
                <div className="Th__title">
                  Факт
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#e9530d75",
                }}
              >
                <div className="Th__title">
                  %
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#e9530d30",
                  cursor: "pointer"
                }}
                onClick={() => setModalEdit({
                  project: object,
                  amountType: 'EFFICIENCY_RATIO_PLAN',
                  amount: calculations.efficiency_factor_plan_amount
                })}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.efficiency_factor_plan_amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.efficiency_factor_plan_amount / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#e9530d30",
                  // cursor: "pointer"
                }}
                // onClick={() => setModalEdit({
                //   project: object,
                //   amountType: 'EFFICIENCY_RATIO_PLAN_FZ',
                //   amount: calculations.efficiency_factor_fz_plan_amount
                // })}
              >
                <div className="Td__text Td__text_center">
                  {/*{(!!calculations.efficiency_factor_fz_plan_amount &&*/}
                  {/*    new Intl.NumberFormat("ru-RU").format(*/}
                  {/*      Number(*/}
                  {/*        calculations.efficiency_factor_fz_plan_amount / 100*/}
                  {/*      ).toFixed(2)*/}
                  {/*    )) ||*/}
                  {/*  "-"}*/}

                  {(!!getPlanFz(object, calculations) &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          getPlanFz(object, calculations) / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#e9530d30",
                }}
              >
                <div className="Td__text Td__text_center">
                  {/*{(!!calculations.efficiency_factor_starting_price &&*/}
                  {/*    new Intl.NumberFormat("ru-RU").format(*/}
                  {/*      Number(*/}
                  {/*        calculations.efficiency_factor_starting_price * 0.7 / 100*/}
                  {/*      ).toFixed(2)*/}
                  {/*    )) ||*/}
                  {/*  "-"}*/}

                  {(!!getPlanDate(calculations) &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          getPlanDate(calculations) / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#e9530d30",
                }}
              >
                <div className="Td__text Td__text_center">
                  {/*{(!!calculations.amount_compete_procurement &&*/}
                  {/*    new Intl.NumberFormat("ru-RU").format(*/}
                  {/*      Number(*/}
                  {/*        calculations.amount_compete_procurement / 100*/}
                  {/*      ).toFixed(2)*/}
                  {/*    )) ||*/}
                  {/*  "-"}*/}

                  {(!!getPlanFact(calculations) &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          getPlanFact(calculations) / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#e9530d30",
                }}
              >
                <div className="Td__text Td__text_center">
                  {/*{(!!calculations.efficiency_factor_starting_price &&*/}
                  {/*    (!!calculations.amount_compete_procurement*/}
                  {/*      ? (calculations.amount_compete_procurement /*/}
                  {/*      ((calculations.efficiency_factor_starting_price * 0.7) / 100)*/}
                  {/*    ).toFixed(2) + "%"*/}
                  {/*      : "-")) ||*/}
                  {/*  "-"}*/}

                  {(!!getPlanPercent(calculations) &&
                      new Intl.NumberFormat("ru-RU").format(
                        getPlanPercent(calculations).toFixed(2)
                      ) + " %")  ||
                    "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        {!!modalEdit && (
          <ModalProjectAmountEdit
            project={modalEdit.project}
            amountType={modalEdit.amountType}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};
