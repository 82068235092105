import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectAmountEdit from "./ModalProjectAmountEdit";
import ModalProjectPurchaseWayEdit from "./ModalProjectPurchaseWayEdit";

export const CalcEpTable = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>Основания заключения с ЕП</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  Статьи
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  План
                  <Tooltip
                    text={
                      'Редактировать'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  Факт
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года", при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена"'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            {(!!calculations.ep_ways && calculations.ep_ways.map((epWay, index) => (
              <tr
                key={index}
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                    width: '50%'
                  }}
                >
                  <div className="Td__text Td__text_left">
                    {(!!epWay.title && epWay.title) || "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                    cursor: "pointer"
                  }}
                  onClick={() => setModalEdit({
                    project: object,
                    purchaseWay: epWay.purchase_way,
                    amount: epWay.plan_amount
                  })}
                >
                  <div className="Td__text Td__text_center">
                    {(!!epWay.plan_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(epWay.plan_amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!epWay.amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(epWay.amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
              </tr>
            )))}
            </tbody>
          </table>
        </div>

        {!!modalEdit && (
          <ModalProjectPurchaseWayEdit
            project={modalEdit.project}
            purchaseWay={modalEdit.purchaseWay}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};
