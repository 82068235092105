import React from "react";
import { Icon, Tooltip } from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";

export const CalcEShopTable = (props) => {
  const { calculations } = props;

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>Расчет эл. магазина</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Цена договора
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Цена&nbsp;договора"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Сумма начатых процедур
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Начальная&nbsp;цена"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Сумма текущего года
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года", при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Сумма экономии
                  <Tooltip
                    text={
                      'Суммируется&nbsp;разница&nbsp;между "Цена&nbsp;договора"&nbsp;и&nbsp;"Начальная&nbsp;цена"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.amount_e_shop / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.starting_price_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.starting_price_e_shop / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_current_year_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.amount_current_year_e_shop / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_savings_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.amount_savings_e_shop / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};
