import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useList,
  useNotification,
  useObject
} from "@vokymlak/kabinet-ui";
import {
  CONTRACT_VALIDITY_TYPE_CURRENT,
  CONTRACT_VALIDITY_TYPE_LASTING,
  CONTRACT_VALIDITY_TYPE_ROLLING
} from "../../../../../config/constants";

function ModalContractAdd(props) {
  const [purchaseBaseId, setPurchaseBaseId] = useState(null);
  const [isIntermediateExecution, setIsIntermediateExecution] = useState(false);
  const [isUis, setIsUis] = useState(false);
  const [isMspPurchase, setIsMspPurchase] = useState(false);
  const [isPurchaseWayEp, setIsPurchaseWayEp] = useState(false);
  const [isEmPurchase, setIsEmPurchase] = useState(false);

  const {
    list: [listLawsContractorStatuses],
  } = useList({apiUrl: "/api/laws-contract-statuses", rootUrl: ROOT_URL});
  const {
    list: [listPurchaseBasis],
  } = useList({apiUrl: "/api/purchase-basis", rootUrl: ROOT_URL});

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputContractNumber = useRef(null);
  const inputRegistryNumber = useRef(null);
  const inputUisNumber = useRef(null);

  const inputBasisConclusion = useRef(null);

  const inputDateViewed = useRef(null);
  const inputDateValidity = useRef(null);

  const inputSubject = useRef(null);

  const inputAmount = useRef(null);

  const inputSupplier = useRef(null);

  const inputNote = useRef(null);
  const inputRegistrationErrorNote = useRef(null);

  const inputPlanAmount = useRef(null);
  const inputStartingPrice = useRef(null);
  const inputAmountCurrentYear = useRef(null);

  const inputLegalResult = useRef(null);

  const inputAmountPayments = useRef(null);
  const inputPaymentExecution = useRef(null);

  const selectPurchaseBaseId = useRef(null);
  const selectPurchaseWayId = useRef(null);
  const selectContractStatusId = useRef(null);

  const selectContractValidityTypeTag = useRef(null);

  const inputCountAppeal = useRef(null);


  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertC = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(0)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const contractNumber = jsonFormatText(inputContractNumber.current.value);
    const registryNumber = jsonFormatText(inputRegistryNumber.current.value);
    const uisNumber = jsonFormatText(inputUisNumber.current.value);

    let basisConclusion = jsonFormatText(inputBasisConclusion.current.value);

    const dateViewed = inputDateViewed.current.value;
    const dateValidity = inputDateValidity.current.value;

    const subject = jsonFormatText(inputSubject.current.value);

    const amount = inputAmount.current.value;

    const supplier = jsonFormatText(inputSupplier.current.value);

    const note = jsonFormatText(inputNote.current.value);
    const registrationErrorNote = jsonFormatText(inputRegistrationErrorNote.current.value);

    const planAmount = inputPlanAmount.current.value;
    const startingPrice = inputStartingPrice.current.value;
    const amountCurrentYear = inputAmountCurrentYear.current.value;

    const legalResult = jsonFormatText(inputLegalResult.current.value);

    const amountPayments = inputAmountPayments.current.value;
    const paymentExecution = jsonFormatText(
      inputPaymentExecution.current.value
    );

    const purchaseBaseId =
      (!!selectPurchaseBaseId.current && jsonFormatText(selectPurchaseBaseId.current.value)) ||
      "";
    const purchaseWayId =
      (!!selectPurchaseWayId.current && jsonFormatText(selectPurchaseWayId.current.value)) ||
      "";
    const contractStatusId =
      (!!selectContractStatusId.current &&
        jsonFormatText(selectContractStatusId.current.value)) ||
      "";

    const contractValidityTypeTag =
      (!!selectContractValidityTypeTag.current &&
        jsonFormatText(selectContractValidityTypeTag.current.value)) ||
      "";

    const countAppeal = inputCountAppeal.current.value;

    if (isPurchaseWayEp && !!purchaseWayId) {
      listPurchaseBasis.forEach((item) => {
        if (!!project && !!project.law && project.law.tag === item.law.tag) {
          if (!!item.ways && !!purchaseBaseId && Number(purchaseBaseId) === item.id) {
            item.ways.forEach((way) => {
              if (way.purchase_way.id === Number(purchaseWayId)) {
                basisConclusion = way.purchase_way.name
              }
            });
          }
        }
      })
    }

    const jsonData = JSON.stringify({
      contract_number: contractNumber,
      registry_number: registryNumber,
      uis_number: uisNumber,

      basis_conclusion: basisConclusion,

      date_viewed: !!dateViewed && dateViewed.length > 0 ? dateViewed : null,
      date_validity:
        !!dateValidity && dateValidity.length > 0 ? dateValidity : null,

      subject: subject,

      supplier: supplier,

      note: note,

      plan_amount: numberConvert(planAmount),
      starting_price: numberConvert(startingPrice),
      amount: numberConvert(amount),
      amount_current_year: numberConvert(amountCurrentYear),

      legal_result: legalResult,

      amount_payments: numberConvert(amountPayments),
      payment_execution: paymentExecution,

      purchase_base_id:
        purchaseBaseId.length > 0
          ? purchaseBaseId === "null"
            ? null
            : Number(purchaseBaseId)
          : null,
      purchase_way_id:
        purchaseWayId.length > 0
          ? purchaseWayId === "null"
            ? null
            : Number(purchaseWayId)
          : null,
      contract_status_id:
        contractStatusId.length > 0
          ? contractStatusId === "null"
            ? null
            : Number(contractStatusId)
          : null,

      is_intermediate_execution: isIntermediateExecution,
      // is_uis: isUis,

      count_appeal: numberConvertC(countAppeal),

      contract_validity_type_tag: contractValidityTypeTag.length > 0
        ? contractValidityTypeTag === "null"
          ? null
          : contractValidityTypeTag
        : null,

      registration_error_note: registrationErrorNote,

      is_msp_purchase: isMspPurchase,
      is_em: isEmPurchase,
    });

    axios
      .post("/api/project/" + props.project.uuid + "/contract/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Договор добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getPurchaseBasisSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.law.tag) {
        const option = {
          value: item.id,
          title: item.name,
        };

        options.push(option);
      }
    });

    return options;
  };

  const getPurchaseWaysSelectOptions = (purchaseBaseId, list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.law.tag) {
        if (!!item.ways && !!purchaseBaseId && purchaseBaseId === item.id) {
          item.ways.map((way) => {
            const option = {
              value: way.purchase_way.id,
              title: way.purchase_way.name,
            };

            options.push(option);
          });
        }
      }
    });

    return options;
  };

  const getContractStatusSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.tag) {
        if (!!item.contract_statuses) {
          item.contract_statuses.map((status) => {
            const option = {
              value: status.contract_status.id,
              title: status.contract_status.name,
            };

            options.push(option);
          });
        }
      }
    });

    return options;
  };

  const onChangeSelectPurchaseBasis = () => {
    const purchaseBaseId = selectPurchaseBaseId.current.value;
    setPurchaseBaseId(
      purchaseBaseId.length > 0
        ? purchaseBaseId === "null"
          ? null
          : Number(purchaseBaseId)
        : null
    );
  };

  return (
    !!project &&
    !!listPurchaseBasis &&
    !!listLawsContractorStatuses && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый
            <div className="Text__signature">Договор</div>
          </div>

          <div className="Form__field">
            <Input reference={inputContractNumber} label="Номер договора"/>
          </div>

          <div className="Form__field">
            <Input reference={inputRegistryNumber} label="Реестровый номер"/>
          </div>

          <div className="Form__field">
            <Input
              reference={inputUisNumber}
              label="Реестровый номер договора в ЕИС"
            />
          </div>

          {/*<div className="Form__field">*/}
          {/*  <div*/}
          {/*    className="Button__swith"*/}
          {/*    style={{*/}
          {/*      padding: "8px",*/}
          {/*      width: "100%",*/}
          {/*      border: isUis*/}
          {/*        ? "2px solid #ffe082"*/}
          {/*        : "2px dashed #20202015",*/}
          {/*      backgroundColor: isUis*/}
          {/*        ? "#ffe082"*/}
          {/*        : "#ffffff",*/}
          {/*      borderRadius: 6,*/}
          {/*      marginTop: 20,*/}
          {/*      display: "flex",*/}
          {/*      alignItems: "center",*/}
          {/*      justifyContent: "space-between",*/}
          {/*      cursor: "pointer",*/}
          {/*    }}*/}
          {/*    onClick={() =>*/}
          {/*      setIsUis(!isUis)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <div*/}
          {/*      className="Swith__text"*/}
          {/*      style={{*/}
          {/*        fontSize: 17,*/}
          {/*        fontWeight: 600,*/}
          {/*        display: "flex",*/}
          {/*        alignItems: "center",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      ЕИС*/}
          {/*    </div>*/}
          {/*    <div*/}
          {/*      className={*/}
          {/*        "Swith Swith_gray" +*/}
          {/*        ((isUis &&*/}
          {/*            " Swith_active Swith_active_gray") ||*/}
          {/*          "")*/}
          {/*      }*/}
          {/*    ></div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="Form__field">
            <Textarea
              reference={inputBasisConclusion}
              label="Основание заключения с ЕП"
              isFixed={true}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputDateViewed}
              type="date"
              isFixed={true}
              label="Дата заключения"
            />
          </div>

          <div className="Form__field">
            <Textarea
              reference={inputSubject}
              label="Предмет"
              isFixed={true}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputPlanAmount}
              type="number"
              label="Сумма по плана закупки"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputStartingPrice}
              type="number"
              label="Начальная цена"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmount}
              type="number"
              label="Цена договора"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmountCurrentYear}
              type="number"
              label="Сумма текущего года"
            />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectContractValidityTypeTag}
              options={[
                {
                  value: "null",
                  title: "Не выбран",
                },
                {
                  value: CONTRACT_VALIDITY_TYPE_CURRENT,
                  title: "Текущий",
                },
                {
                  value: CONTRACT_VALIDITY_TYPE_ROLLING,
                  title: "Переходящий",
                },
                {
                  value: CONTRACT_VALIDITY_TYPE_LASTING,
                  title: "Долгосрочный",
                },
              ]}
              label="Тип действия"
            />
          </div>

          <div className="Form__field">
            <Input reference={inputSupplier} label="Поставщик"/>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectPurchaseBaseId}
              options={
                !!listPurchaseBasis
                  ? getPurchaseBasisSelectOptions(listPurchaseBasis)
                  : getPurchaseBasisSelectOptions([])
              }
              label="Закон-основание"
              onChange={() => onChangeSelectPurchaseBasis()}
            />
          </div>

          {!!purchaseBaseId && (
            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectPurchaseWayId}
                options={
                  !!listPurchaseBasis
                    ? getPurchaseWaysSelectOptions(
                      purchaseBaseId,
                      listPurchaseBasis
                    )
                    : getPurchaseWaysSelectOptions(purchaseBaseId, [])
                }
                value={null}
                label="Способ закупки"
              />
            </div>
          )}

          {!!purchaseBaseId && (
            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPurchaseWayEp
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  // border: "2px dashed #20202015",
                  backgroundColor: isPurchaseWayEp
                    ? "#ffe082"
                    : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsPurchaseWayEp(!isPurchaseWayEp)
                }
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    // color: isPriority ? "#ff3c00" : "#202020",
                  }}
                >
                  Добавить "Способ закупки" в "Основание заключения с ЕП", м?
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPurchaseWayEp &&
                        " Swith_active Swith_active_gray") ||
                      "")
                  }
                ></div>
              </div>
            </div>
          )}

          <div className="Form__field">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isMspPurchase
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                // border: "2px dashed #20202015",
                backgroundColor: isMspPurchase
                  ? "#ffe082"
                  : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() =>
                setIsMspPurchase(!isMspPurchase)
              }
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  // color: isPriority ? "#ff3c00" : "#202020",
                }}
              >
                Закупка у МСП
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isMspPurchase &&
                      " Swith_active Swith_active_gray") ||
                    "")
                }
              ></div>
            </div>
          </div>

          {(!!project && !!project.law && project.law.tag === "44") && (
            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isEmPurchase
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  // border: "2px dashed #20202015",
                  backgroundColor: isEmPurchase
                    ? "#ffe082"
                    : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsEmPurchase(!isEmPurchase)
                }
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    // color: isPriority ? "#ff3c00" : "#202020",
                  }}
                >
                  Закупка в электронном магазине
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isEmPurchase &&
                        " Swith_active Swith_active_gray") ||
                      "")
                  }
                ></div>
              </div>
            </div>
          )}

          <div className="Form__field">
            <Textarea
              reference={inputLegalResult}
              label="Результат правового анализа"
              isFixed={true}
            />
          </div>

          <div className="Form__field">
            <Textarea
              reference={inputNote}
              label="Примечание"
              isFixed={true}
            />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectContractStatusId}
              options={
                !!listLawsContractorStatuses
                  ? getContractStatusSelectOptions(listLawsContractorStatuses)
                  : getContractStatusSelectOptions([])
              }
              label="Статус"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputDateValidity}
              type="date"
              isFixed={true}
              label="Срок действия"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputCountAppeal}
              type="number"
              label="Количество заказов по КП"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmountPayments}
              type="number"
              label="Сумма по платежам"
            />
          </div>
          <div className="Form__field">
            <Textarea
              reference={inputPaymentExecution}
              label="Комментарий к исполнению"
              isFixed={true}
            />
          </div>

          <div className="Form__field">
            <Textarea
              reference={inputRegistrationErrorNote}
              label="Комментарий к ошибке регистрации"
              isFixed={true}
            />
          </div>

          <div className="Form__field Form__field_last">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isIntermediateExecution
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                backgroundColor: isIntermediateExecution
                  ? "#ffe082"
                  : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() =>
                setIsIntermediateExecution(!isIntermediateExecution)
              }
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Промежуточное исполнение
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isIntermediateExecution &&
                      " Swith_active Swith_active_gray") ||
                    "")
                }
              ></div>
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalContractAdd;
