import React from "react";
import {Loading} from "@vokymlak/kabinet-ui";
import {
  CalcProcurementTable
} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcProcurementTable";
import {CalcP4Table} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcP4Table";
import {CalcP5Table} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcP5Table";
import {CalcP02Table} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcP02Table";
import {CalcP03Table} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcP03Table";
import {CalcEShopTable} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcEShopTable";
import {CalcCompeteTable} from "../../../../ScreenObjects/Project/components/ProjectCalcContracts/CalcCompeteTable";

function ProjectCalcContractsView(props) {
  const {
    object,

    calculations
  } = props;

  return (
    <>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
              marginRight: 6,
            }}
          >
            <div>Контрактация</div>
          </div>
        </div>

        <div className="Title__settings">
        </div>
      </div>

      {!calculations && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!calculations && (
        <div className="Page__content show">
          <CalcProcurementTable {...props} />

          {object.law.tag === "44" && <CalcP4Table {...props} />}

          {object.law.tag === "44" && <CalcP5Table {...props} />}

          {object.law.tag === "223" && <CalcP02Table {...props} />}

          {object.law.tag === "223" && <CalcP03Table {...props} />}

          <CalcEShopTable {...props} />

          <CalcCompeteTable {...props} />
        </div>
      )}
    </>
  );
}

export default ProjectCalcContractsView;
