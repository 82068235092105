import React, { useEffect, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useList,
  Modal,
  useObject,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";

function ModalGeneralTaskProjectAdd(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/general-task/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [selectList, setSelectList] = useState([]);

  const [tumbler, setTumbler] = useState("a");
  const {
    list: [listProjects],
  } = useList({
    apiUrl: "/api/projects/list",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (!!listProjects && listProjects.length > 0) {
      if (!!tumbler && tumbler === "a") {
        setSelectList([...listProjects]);
      }
      if (!!tumbler && tumbler === "b") {
        setSelectList([...listProjects.filter((p) => p.law.tag === "44")]);
      }

      if (!!tumbler && tumbler === "c") {
        setSelectList([...listProjects.filter((p) => p.law.tag === "223")]);
      }
    } else {
      setSelectList([]);
    }
  }, [listProjects, tumbler]);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify(selectList.map((p) => p.uuid));

    axios
      .put(
        "/api/general-task/" + props.object.uuid + "/projects/add",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Проекты добавлены в общую задачу", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteItem = (filters, filter, setFilter) => {
    let index = null;
    if (!!filters) {
      filters.forEach((sf, i) => {
        if (sf.uuid === String(filter.uuid)) {
          index = i;
        }
      });
    }
    if (!!index || String(index) === "0") {
      filters.splice(index, 1);
      setFilter([...filters]);
    }
  };

  return (
    !!object &&
    !!listProjects && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            {object.title}
            <div className="Text__signature">Добавить в задачу</div>
          </div>

          <div className="Form__field">
            <ul
              className={styleTumbler._}
              style={{
                justifyContent: "space-between",
                backgroundColor: "#f5f5f5",
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                      tumbler === "a" &&
                      " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("a")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Все
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                      tumbler === "b" &&
                      " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("b")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  По 44
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                      tumbler === "c" &&
                      " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("c")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  По 223
                </a>
              </li>
            </ul>
          </div>

          <div
            className="Form__field Form__field_last"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {!!selectList &&
              selectList.map((project) => (
                <div
                  key={"item_" + project.uuid}
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    padding: "3px 3px 3px 9px",
                    backgroundColor: "#dfdfdf",
                    margin: "0 2px",
                    borderRadius: 24,
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: 4,
                  }}
                >
                  <span>{project.name}</span>
                  <ButtonIcon
                    tooltipText={"Убрать"}
                    iconName={"custom-circle-close"}
                    iconColor={"#20202075"}
                    size={18}
                    style={{ marginLeft: 3 }}
                    onClick={() =>
                      deleteItem(selectList, project, setSelectList)
                    }
                  />
                </div>
              ))}
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalGeneralTaskProjectAdd;
