import React, {Fragment, useEffect, useState} from "react";
import {NavLink, useMatch, useNavigate, useParams} from "react-router-dom";
import "./AppealsMenuTree.scss";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import { ROOT_URL } from "../../../config/url";
import {
  ButtonIcon,
  getAccountFio,
  Icon,
  Search,
  Tooltip,
  useAction,
  useList,
  useObject,
  useUnroll
} from "@vokymlak/kabinet-ui";

export const AppealsMenuTree = (props) => {
  const [ selectEmployee, setSelectEmployee ] = useState(null);
  const [ selectCompany, setSelectCompany ] = useState(null);

  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({ apiUrl: "/api/appeal/tree", rootUrl: ROOT_URL});

  useAction("appeals", () => updateList());

  useEffect(() => {
    props.setSelectEmployee(selectEmployee)
    props.setSelectCompany(selectCompany)

  }, [selectEmployee, selectCompany])

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="AppealsMenuTreeMenu">
        <ul className="Menu__list" style={{padding: 0}}>
          <li className="List__link">
            <div
              onClick={() => (setSelectCompany(null), setSelectEmployee(null))}
              className={"Link__text " + (!selectCompany && !selectEmployee && "active")}
              style={{
                padding: "2px  2px 4px 6px",
              }}
            >
              По всем
            </div>
          </li>
        </ul>

        {!!list && (
          <ul className="Menu__list">
            {list.map((item) => (
              !!item.employee && (
                <Fragment key={item.employee.uuid}>
                  <li
                    className={"List__group " + (!selectCompany && !!selectEmployee && selectEmployee.uuid === item.employee.uuid && "active")}
                    style={{
                      // backgroundColor: "#20202010",
                      backgroundColor: !selectCompany && !!selectEmployee && selectEmployee.uuid === item.employee.uuid ? "white" : "#20202005",
                      flexDirection: "column",
                      borderRadius: 7,
                    }}
                  >
                    <div
                      className={"List__group " + (!selectCompany && !!selectEmployee && selectEmployee.uuid === item.employee.uuid && "active")}
                      onClick={() => (setSelectEmployee(item.employee), setSelectCompany(null))}
                      style={{
                        width: "100%",
                        padding: "4px  2px 4px 6px",
                        marginBottom: 0,
                        color: "#202020",
                      }}
                    //   style={{
                    //   fontSize: 16px;
                    //   cursor: pointer;
                    //   margin-bottom: 8px;
                    //   display: flex;
                    //   flex-direction: column;
                    //   flex-wrap: nowrap;
                    //   justify-content: space-between;
                    //   align-items: center;
                    // }}
                    >
                      <div>
                        <div style={{
                          marginRight: 2,
                        }}>
                        {getAccountFio(item.employee)}
                        </div>
                            <div style={{
                              display: "inline-block",
                            }}>
                                <div style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}>
                                  {!!item.count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#20202033",
                                      color: "#202020",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.count}</div>
                                  )}

                                  {!!item.new_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#509d1d",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.new_count}</div>
                                  )}

                                  {!!item.work_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#0061ff",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.work_count}</div>
                                  )}

                                  {!!item.return_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#f44336",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.return_count}</div>
                                  )}
                                </div>
                                  </div>
                      </div>
                      {!!item.companies && (
                        <ButtonIcon
                          tooltipText={null}
                          iconName={
                            !!selectEmployee && selectEmployee.uuid === item.employee.uuid
                              ? "custom-expand-circle-up"
                              : "custom-expand-circle-down"
                          }
                          iconColor={"#20202095"}
                          size={18}
                          style={{marginLeft: 12, marginRight: 3}}
                          onClick={() => null}
                        />
                      )}
                    </div>

                      {!!selectEmployee && selectEmployee.uuid === item.employee.uuid && !!item.companies && (
                        <ul className="List__menu" style={{
                          paddingLeft: 4,
                          paddingRight: 4,
                          marginTop: 0,
                          marginBottom: 0,
                          border: "none",
                          width: "100%",
                        }}>
                          {item.companies.map((company) => (
                            <li key={company.company.uuid} className="List__link"
                            style={{
                              backgroundColor: !!selectCompany && selectCompany.uuid === company.company.uuid ? "white" : "#20202010",
                              boxShadow: !!selectCompany && selectCompany.uuid === company.company.uuid && "0 16px 20px rgba(0, 0, 0, 0.07)",
                              padding: "4px 6px",
                              borderRadius: 5,
                              marginBottom: 4
                            }}>
                              <div
                                className={"Link__text " + (!!selectCompany && selectCompany.uuid === company.company.uuid && "active")}
                                onClick={() => setSelectCompany(company.company)}
                                style={{
                                  color: "#202020"
                                }}
                              >
                                <span style={{
                                  marginRight: 2,
                                }}>
                                {company.company.short_name}
                                  </span>
                                <span style={{
                                  display: "inline-block",
                                }}>
                                <div style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}>
                                  {!!company.count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#20202033",
                                      color: "#202020",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{company.count}</div>
                                  )}

                                  {!!company.new_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#509d1d",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{company.new_count}</div>
                                  )}

                                  {!!company.work_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#0061ff",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{company.work_count}</div>
                                  )}

                                  {!!company.return_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#f44336",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{company.return_count}</div>
                                  )}
                                </div>
                                  </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}

                  </li>
                </Fragment>
              )
            ))}
          </ul>
        )}

      </div>
    </ScrollBlock>
  );
};
