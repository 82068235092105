import React, { useRef, useState } from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  jsonFormatText,
  isValidateNullText, Input, formatBytes, Icon, getAccountFio, TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import ModalContractItemSelect from "./ModalContractItemSelect";

function ModalAppealAdd(props) {
  const inputTicketFile = useRef(null);
  const [files, setFiles] = useState(null);
  const fileSend = (event) => {
    const files = event.target.files;
    setFiles(Array.from(files));
  };

  const [selectItem, setSelectItem] = useState(null);
  const [isModalSelectItem, setIsModalSelectItem] = useState(false);

  const inputSourceFunding = useRef(null);
  const inputRequirement = useRef(null);
  const inputAmount = useRef(null);
  const inputPurchaseMethod = useRef(null);
  const inputDatePlacement = useRef(null);
  const inputDateAgreement = useRef(null);
  const inputContractRequisite = useRef(null);
  const inputQuestion = useRef(null);
  const inputNote = useRef(null);
  const inputContractNumber = useRef(null);
  const inputDocumentType = useRef(null);
  const inputDocumentDate = useRef(null);
  const selectProjectUuid = useRef(null);

  const changeSelectProject = () => {
    setSelectItem(null);
  }

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const {
    list: [projects],
  } = useList(
    {
      apiUrl: "/api/client/projects/list",
      rootUrl: ROOT_URL,
      callbackError: () => {
        props.handleClose();
      },
    });

  const { notification } = useNotification();

  const changeAdd = (isDraft) => {
    let formData = new FormData()

    const projectUuid = selectProjectUuid.current.value;

    // Обязательно указать проект.
    if (projectUuid.length > 0 && projectUuid !== "null") {
      formData.append("project_uuid", projectUuid);
    } else {
      notification("Укажите проект", "red");
      return;
    }

    let sourceFunding = null; // (text)
    let requirement = null; // (text)
    let amount = null; // (number)
    let purchaseMethod = null; // (select)
    let datePlacement = null; // (date)
    let dateAgreement = null; // (date)
    let contractRequisite = null; // (text)
    let question = null; // (text)
    let note = null; // (text)
    let contractNumber = null; // (text)
    let documentType = null; // (select)
    let documentDate = null; // (date)

    if (props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      requirement = jsonFormatText(inputRequirement.current.value); // * (text)
      amount = inputAmount.current.value; // * (number)
      purchaseMethod = inputPurchaseMethod.current.value; // * (select)
      datePlacement = inputDatePlacement.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding) || isValidateNullText(requirement)
        || isValidateNullText(amount)
        || isValidateNullText(purchaseMethod) || purchaseMethod === "null"
        || isValidateNullText(datePlacement)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("source_funding", sourceFunding);
      formData.append("requirement", requirement);
      formData.append("amount", numberConvert(amount));
      formData.append("purchase_method",
        purchaseMethod.length > 0
        ? purchaseMethod === "null"
          ? null
          : purchaseMethod
        : null);
      formData.append("date_placement", !!datePlacement && datePlacement.length > 0 ? datePlacement : null);
      formData.append("note", (!!note && note) || "");
    }

    if (props.type === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT") {
      contractRequisite = jsonFormatText(inputContractRequisite.current.value); // * (text)
      question = jsonFormatText(inputQuestion.current.value); // * (text)

      if (isValidateNullText(contractRequisite) || isValidateNullText(question)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("contract_requisite", contractRequisite);
      formData.append("question", question);
    }

    if (props.type === "COORDINATION_OF_INDEPENDENT_GUARANTEES") {
      note = jsonFormatText(inputNote.current.value); // * (text)

      if (isValidateNullText(note)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("note", (!!note && note) || "");
    }

    if (props.type === "DOCUMENTS_FOR_PURCHASE") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      purchaseMethod = inputPurchaseMethod.current.value; // * (select)
      datePlacement = inputDatePlacement.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding)
        || isValidateNullText(purchaseMethod) || purchaseMethod === "null"
        || isValidateNullText(datePlacement)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("source_funding", sourceFunding);
      formData.append("purchase_method",
        purchaseMethod.length > 0
          ? purchaseMethod === "null"
            ? null
            : purchaseMethod
          : null);
      formData.append("date_placement", !!datePlacement && datePlacement.length > 0 ? datePlacement : null);
      formData.append("note", (!!note && note) || "");
    }

    if (props.type === "REGISTRATION_AGREEMENT") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      requirement = jsonFormatText(inputRequirement.current.value); // * (text)
      amount = inputAmount.current.value; // * (number)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding) || isValidateNullText(requirement) || isValidateNullText(amount)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("source_funding", sourceFunding);
      formData.append("requirement", requirement);
      formData.append("amount", numberConvert(amount));
      formData.append("note", (!!note && note) || "");
    }

    if (props.type === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION") {
      dateAgreement = inputDateAgreement.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // * (text)

      if (isValidateNullText(dateAgreement) || isValidateNullText(note)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("date_agreement", !!dateAgreement && dateAgreement.length > 0 ? dateAgreement : null);
      formData.append("note", (!!note && note) || "");
    }

    if (props.type === "REGISTRATION_FORMALIZATION") {
      // contractNumber = jsonFormatText(inputContractNumber.current.value); // * (text)
      documentType = inputDocumentType.current.value; // * (select)
      documentDate = inputDocumentDate.current.value; // * (date)

      if (
        //isValidateNullText(contractNumber) ||
        isValidateNullText(documentType) ||
        isValidateNullText(documentDate)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      if (selectItem === null) {
        notification("Укажите номер договора", "red");
        return;
      } else {
        formData.append("contract_uuid", selectItem.uuid);
      }

      // formData.append("contract_number", contractNumber);
      formData.append("document_type",
        documentType.length > 0
          ? documentType === "null"
            ? null
            : documentType
          : null);
      formData.append("document_date", !!documentDate && documentDate.length > 0 ? documentDate : null);

    }

    if (props.type === "ONE_MORE_QUESTION") {
      question = jsonFormatText(inputQuestion.current.value); // * (text)

      if (isValidateNullText(question)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("question", question);
    }

    // if (props.type !== "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE") {
      // if (!files || (!!files && files.length === 0)) {
      //   notification("Прикрепите файл", "red");
      //   return;
      // }

      if (!!files && files.length > 0) {

        if (files.filter(f => f.size > 262144000).length > 0) {
          notification("Один из файлов превышает допустимый размер в 25Мб", "red");
          return;
        }

        if (files.reduce(function (previousValue, currentValue) {
          return {
            size: previousValue.size + currentValue.size
          }
        }).size > (262144000 * 10)) {
          notification("Общий размер файлов превышает 250Мб", "red");
          return;
        }

        if (files.length > 10) {
          notification("Можно прикрепить не больше 10 файлов за раз", "red");
          return;
        }

        files.forEach(f => {
          formData.append("files", f);
        })
      }
    // }

    formData.append("appeal_type_tag", !!props.type ? props.type : null);

    axios
      .post("/api/my/appeal/add" + (isDraft ? "?draft=true" : ""), formData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification(isDraft ? "Обращение сохранено как черновик" : "Обращение подано", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getNameAppeal = (tag) => {
    switch (tag) {
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "Включение в план закупки / план график";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "Согласование договора / дополнительного соглашения";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "Согласование независимых гарантий";

      case "DOCUMENTS_FOR_PURCHASE":
        return "Документы для закупки";

      case "REGISTRATION_AGREEMENT":
        return "Договор на регистрацию";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "Дополнительное соглашение на регистрацию";

      case "REGISTRATION_FORMALIZATION":
        return "Исполнение на регистрацию";

      case "ONE_MORE_QUESTION":
        return "Иной вопрос";
    }
    return null;
  }

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }

  const getProjectSelectOptions = (list) => {
    const options = [
      ...(list.length === 0 ? [
        {
          value: "null",
          title: "Не указан",
        },
      ] : [])
    ];

    list.map((item) => {
      let title = !!item.project_year ? item.project_year + " " : ""
      title = title + item.name
      title = title + (!!item.law ? " (ФЗ-" + item.law.name + ")" : "")

      const option = {
        value: item.uuid,
        title: title,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <>
    <Modal handleClose={() => null} contentStyle={{
      width: 520
    }}>
      <div className="Form">
        <div className="Form__title" style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "center",
          fontWeight: 600
        }}>
          <div style={{
            marginRight: 12
          }}>
            <Icon size={56} name={getIconNameAppeal(props.type)}/>
          </div>
          <div>
            {(!!getNameAppeal(props.type) && getNameAppeal(props.type)) || "Новое"}
            <div className="Text__signature">Обращение</div>
          </div>
        </div>

        <div className="Form__field">
          <div
            style={{
              fontSize: 15,
              fontWeight: 400,
              backgroundColor: "#20202010",
              borderRadius: 5,
              padding: "10px 12px",
            }}
          >
            {props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
              <>
                Направление проекта контракта и источников финансирования для включения
              </>
            )}
            {props.type === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
              <>
                Направление проекта договора, проекта дополнительного соглашения для правовой проверки юристом
              </>
            )}
            {props.type === "COORDINATION_OF_INDEPENDENT_GUARANTEES" && (
              <>
                Направление проекта независимой гарантии для правовой проверки юристом
              </>
            )}
            {props.type === "DOCUMENTS_FOR_PURCHASE" && (
              <>
                Направление потребности и документов для проведения закупочной процедуры.<br/>
                Необходимые документы:<br/>
                -техническое задание;<br/>
                -расчет начально-максимальной стоимости договора;<br/>
                -коммерческие предложения;<br/>
                -источники финансирования;<br/>
                -проект договора.<br/>
              </>
            )}
            {props.type === "REGISTRATION_AGREEMENT" && (
              <>
                Направление подписанного документа для регистрации с приложенными источниками финансирования
              </>
            )}
            {props.type === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
              <>
                Направление подписанного документа для регистрации
              </>
            )}
            {props.type === "REGISTRATION_FORMALIZATION" && (
              <>
                Направление актов выполненных работ / товарных накладных / УПД / платежных поручений на размещение
              </>
            )}
            {props.type === "ONE_MORE_QUESTION" && (
              <>
                Вопросы иного характера
              </>
            )}
          </div>
        </div>

        <div className="Form__field">
          <Select
            reference={selectProjectUuid}
            isFixed={true}
            options={!!projects ? getProjectSelectOptions(projects) : getProjectSelectOptions([])}
            label="Проект"
            onChange={() => changeSelectProject()}
          />
        </div>

        {(props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
          props.type === "DOCUMENTS_FOR_PURCHASE" ||
          props.type === "REGISTRATION_AGREEMENT") && (
          <div className="Form__field">
            <Input
              reference={inputSourceFunding}
              label="Источник финансирования (КВФО, КЦСР, КВР, КОСГУ) *"
            />
          </div>
        )}


        {(props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
          props.type === "REGISTRATION_AGREEMENT") && (
          <div className="Form__field">
            <Textarea
              reference={inputRequirement}
              label="Потребность / договор *"
            />
          </div>
        )}

        {(props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
          props.type === "REGISTRATION_AGREEMENT") && (
          <div className="Form__field">
            <Input
              reference={inputAmount}
              type="number"
              label="Сумма *"
            />
          </div>
        )}

        {(props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
          props.type === "DOCUMENTS_FOR_PURCHASE") && (
          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputPurchaseMethod}
              options={[
                {
                  value: "null",
                  title: "Не выбран",
                },
                {
                  value: "EP",
                  title: "ЕП. "
                },
                {
                  value: "KP",
                  title: "КП. "
                }
              ]}
              label="Способ закупки *"
            />
          </div>
        )}

        {props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
          <div className="Form__field">
            <Input
              reference={inputDatePlacement}
              type="date"
              isFixed={true}
              label="Предполагаемый срок размещения закупки *"
            />
          </div>
        )}

        {props.type === "DOCUMENTS_FOR_PURCHASE" && (
          <div className="Form__field">
            <Input
              reference={inputDatePlacement}
              type="date"
              isFixed={true}
              label="Планируемый срок размещения закупки *"
            />
          </div>
        )}

        {props.type === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
          <div className="Form__field">
            <Input
              reference={inputContractRequisite}
              label="Реквизит договора / ДС *"
            />
            <div className={"Field__label"}>* при направлении ДС, указывать номер договора к которому прилагается ДС
            </div>
          </div>
        )}

        {(props.type === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" ||
          props.type === "ONE_MORE_QUESTION") && (
          <div className="Form__field">
            <Textarea
              reference={inputQuestion}
              label="Вопрос *"
            />
          </div>
        )}

        {props.type === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
          <div className="Form__field">
            <Input
              reference={inputDateAgreement}
              type="date"
              isFixed={true}
              label="Дата ДС *"
            />
          </div>
        )}

        {props.type === "REGISTRATION_FORMALIZATION" && (
          <>
          {/*<div className="Form__field">*/}
          {/*  <Input*/}
          {/*    reference={inputContractNumber}*/}
          {/*    label="Номер договора *"*/}
          {/*  />*/}
          {/*</div>*/}

            {!selectItem && (
              <div className="Form__field"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номер договора *
                </div>
                <div className="Field__text">
                  -
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__field"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номер договора *
                </div>
                <div className="Field__text">
                  <div>
                    <div>
                      {!!selectItem.project && (
                        <div>
                          {!!selectItem.project.name && (
                            <span
                              style={{
                                marginRight: 4,
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                                    {selectItem.project.project_year}{" "}{selectItem.project.name}
                              </span>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      {selectItem.registry_number && (
                        <span style={{
                          marginRight: 6
                        }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                          {selectItem.registry_number + " "}
                              </span>
                      )}
                      {selectItem.contract_number}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {props.type === "REGISTRATION_FORMALIZATION" && (
          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputDocumentType}
              options={[
                {
                  value: "null",
                  title: "Не выбран",
                },
                {
                  value: "ACT",
                  title: "Акт"
                },
                {
                  value: "PAYMENT",
                  title: "Платёж"
                }
              ]}
              label="Документ (акт / платёж) *"
            />
          </div>
        )}

        {props.type === "REGISTRATION_FORMALIZATION" && (
          <div className="Form__field">
            <Input
              reference={inputDocumentDate}
              type="date"
              isFixed={true}
              label="Дата документа *"
            />
          </div>
        )}

        {(props.type === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
          props.type === "DOCUMENTS_FOR_PURCHASE" ||
          props.type === "REGISTRATION_AGREEMENT") && (
          <div className="Form__field">
            <Textarea
              reference={inputNote}
              label="Примечание"
            />
          </div>
        )}

        {(props.type === "COORDINATION_OF_INDEPENDENT_GUARANTEES" ||
          props.type === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION") && (
          <div className="Form__field">
            <Textarea
              reference={inputNote}
              label="Примечание *"
            />
          </div>
        )}


        {/*{props.type !== "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (*/}
        <>
          <div className="Form__field">
            <Input
              reference={inputTicketFile}
              type="file"
              label="Файлы"
              isFixed={true}
              onChange={(event) => fileSend(event)}
              isMultiple={true}
            />
          </div>
          <div className="Form__field Form__field_last">
            {!!files && files.map((file, index) => (
              <div
                key={'file_' + index}
                style={{
                  minWidth: "auto",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f1f1f1",
                  padding: "6px 10px 6px 6px",
                  borderRadius: 5,
                  fontSize: 15,
                  marginBottom: 4,
                  marginTop: 4
                }}>
                {!!file.name && (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textTransform: "uppercase",
                      fontSize: 10,
                      fontWeight: 900,
                      borderRadius: 4,
                      color: "white",
                      backgroundColor: getColorFile(file.name.split(".").pop()),
                      marginRight: 8,
                      padding: "10px 4px",
                      minWidth: 42
                    }}>
                                    {file.name.split(".").pop()}
                                  </span>
                )}
                <div>
                  <div style={{
                    fontSize: 15
                  }}>
                    {file.name.slice(0, file.name.lastIndexOf("."))}
                  </div>

                  <div style={{
                    fontSize: 13
                  }}>
                    {formatBytes(file.size)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        {/*)}*/}

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue" onClick={() => changeAdd(false)}>
            Подать
          </button>
        </div>

        <div className="Form__actions Form__actions_center" onClick={() => changeAdd(true)}>
          <button className="Action__button Action__button_all Action__button_gray">
            Сохранить как черновик
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </div>
    </Modal>

      {isModalSelectItem && (
        <ModalContractItemSelect
          item={selectItem}
          successfulRequest={item =>
            setSelectItem(item)
          }
          selectProjectUuid={selectProjectUuid.current.value}
          handleClose={() => setIsModalSelectItem(false)}
        />
      )}
    </>
  );
}

export default ModalAppealAdd;
