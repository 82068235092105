import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectPurchaseWayEdit from "./ModalProjectPurchaseWayEdit";

export const CalcP03Table = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  function getP03(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) > 30_000_000_00
        ? 30_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) < 30_000_000_00
          ? Number((calculations.sgoz_amount) * 0.5)
          : 0
    )
  }

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>03. Закупки для учреждений социальной сферы на сумму, не превышающую
          установленный размер (600 тыс.руб.)
        </div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  СГОЗ
                  <Tooltip
                    text={"Сумма&nbsp;СГОЗ"}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  03. - 223 (50%)
                  <Tooltip
                    text={
                      "50%&nbsp;от&nbsp;СГОЗ,&nbsp;до&nbsp;30&nbsp;000&nbsp;000"
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  03. - 223 (сумма)
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года" (03.)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  03. - 223 (Переходящие)
                  <Tooltip
                    text={
                      'Редактировать'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  03. - 223 (разница)
                  <Tooltip
                    text={
                      "Разница&nbsp;между&nbsp;СГОЗ&nbsp;50% и&nbsp;суммой&nbsp;(03.)"
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.sgoz_amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.sgoz_amount / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP03(calculations) &&
                      new Intl.NumberFormat("ru-RU").format((getP03(calculations) / 100).toFixed(2)))
                    || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p03_starting_price &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.p03_starting_price / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                  cursor: "pointer"
                }}
                onClick={() => setModalEdit({
                  project: object,
                  purchaseWay: calculations.p03_purchase_way,
                  amount: calculations.p03_transition_price
                })}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p03_transition_price &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.p03_transition_price / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP03(calculations) &&
                    new Intl.NumberFormat("ru-RU").format(
                      ((
                        getP03(calculations)
                        - calculations.p03_starting_price
                        - calculations.p03_transition_price
                      ) / 100).toFixed(2)
                    )) || "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        {!!modalEdit && (
          <ModalProjectPurchaseWayEdit
            project={modalEdit.project}
            purchaseWay={modalEdit.purchaseWay}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};
