import { useEffect, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import {
  useAction,
  useList,
  useLocalStorage,
  useNotification,
} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function useTasks() {
  const { notification } = useNotification();
  const {
    list: [listContractGroups],
  } = useList({
    apiUrl: "/api/contract-groups",
    rootUrl: ROOT_URL,
  });
  const [contractGroup, setContractGroup] = useState(null);

  const {
    list: [listControlPoints],
  } = useList({
    apiUrl: "/api/control-points",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listProjects],
  } = useList({
    apiUrl: "/api/projects/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const [selectMonth, setSelectMonth] = useState(moment().format("MM"));
  const [selectYear, setSelectYear] = useState(moment().format("YYYY"));
  const [selectDate, setSelectDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDay] = useState(moment);
  const [calendarDays, setCalendarDays] = useState(
    new Date(Number(selectYear), Number(selectMonth), 0).getDate()
  );
  const [days, setDays] = useState(null);

  useEffect(() => {
    setCalendarDays(
      new Date(Number(selectYear), Number(selectMonth), 0).getDate()
    );
  }, [selectYear, selectMonth]);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  const [filterTasks, setFilterTasks] = useLocalStorage("newFilterTasksV2", {
    filterControlPoints: [],
    filterProjects: [],
    filterEmployees: [],
    filterCuratorEmployees: [],
    filterSpecialistEmployees: [],
    filterExecutorEmployees: [],

    isGeneralTasks: true,
    isGeneralTasksOverdue: true,

    isProjectTasksOverdue: true,
    isProjectTasks: true,

    isControlPointTasks: true,
    isControlPointTasksOverdue: true,

    isExecutionTasks: true,
    isExecutionTasksOverdue: true,
  });

  const [filterControlPoints, setFilterControlPoints] = useState(
    filterTasks.filterControlPoints || []
  );
  const [filterProjects, setFilterProjects] = useState(
    filterTasks.filterProjects || []
  );
  const [filterEmployees, setFilterEmployees] = useState(
    filterTasks.filterEmployees || []
  );

  const [filterCuratorEmployees, setFilterCuratorEmployees] = useState(
    filterTasks.filterCuratorEmployees || []
  );
  const [filterSpecialistEmployees, setFilterSpecialistEmployees] = useState(
    filterTasks.filterSpecialistEmployees || []
  );
  const [filterExecutorEmployees, setFilterExecutorEmployees] = useState(
    filterTasks.filterExecutorEmployees || []
  );

  const [isGeneralTasks, setIsGeneralTasks] = useState(filterTasks.isGeneralTasks);
  const [isGeneralTasksOverdue, setIsGeneralTasksOverdue] = useState(filterTasks.isGeneralTasksOverdue);
  const [isProjectTasks, setIsProjectTasks] = useState(filterTasks.isProjectTasks);
  const [isProjectTasksOverdue, setIsProjectTasksOverdue] = useState(filterTasks.isProjectTasksOverdue);
  const [isControlPointTasks, setIsControlPointTasks] = useState(filterTasks.isControlPointTasks);
  const [isControlPointTasksOverdue, setIsControlPointTasksOverdue] = useState(filterTasks.isControlPointTasksOverdue);
  const [isExecutionTasks, setIsExecutionTasks] = useState(filterTasks.isExecutionTasks);
  const [isExecutionTasksOverdue, setIsExecutionTasksOverdue] = useState(filterTasks.isExecutionTasksOverdue);

  useEffect(() => {
    setFilterTasks({
      filterControlPoints,
      filterProjects,
      filterEmployees,
      filterCuratorEmployees,
      filterSpecialistEmployees,
      filterExecutorEmployees,
      isGeneralTasks,
      isGeneralTasksOverdue,
      isProjectTasks,
      isProjectTasksOverdue,
      isControlPointTasks,
      isControlPointTasksOverdue,
      isExecutionTasks,
      isExecutionTasksOverdue,
    });
  }, [
    filterControlPoints,
    filterProjects,
    filterEmployees,
    filterCuratorEmployees,
    filterSpecialistEmployees,
    filterExecutorEmployees,
    isGeneralTasks,
    isGeneralTasksOverdue,
    isProjectTasks,
    isProjectTasksOverdue,
    isControlPointTasks,
    isControlPointTasksOverdue,
    isExecutionTasks,
    isExecutionTasksOverdue,
  ]);

  const getUrlParams = () => {
    let params = {
      day: selectDate,
      month: selectYear + "-" + selectMonth + "-01",
      employees:
        (!!filterEmployees &&
          filterEmployees.length > 0 &&
          filterEmployees.join(",")) ||
        "",
      curators:
        (!!filterCuratorEmployees &&
          filterCuratorEmployees.length > 0 &&
          filterCuratorEmployees.join(",")) ||
        "",
      specialists:
        (!!filterSpecialistEmployees &&
          filterSpecialistEmployees.length > 0 &&
          filterSpecialistEmployees.join(",")) ||
        "",
      executors:
        (!!filterExecutorEmployees &&
          filterExecutorEmployees.length > 0 &&
          filterExecutorEmployees.join(",")) ||
        "",
      projects:
        (!!filterProjects &&
          filterProjects.length > 0 &&
          filterProjects.join(",")) ||
        "",
      "control-points":
        (!!filterControlPoints &&
          filterControlPoints.length > 0 &&
          filterControlPoints.join(",")) ||
        "",
    };

    if (!!contractGroup) {
      params = {
        ...params,
        "purchase-basis": [],
        "is-purchase-base-null": contractGroup.is_purchase_base_null,
        "contract-statuses": [],
        "is-contract-status-null": contractGroup.is_contract_status_null,
      };
      if (!!contractGroup.purchase_basis) {
        contractGroup.purchase_basis.forEach((a) => {
          if (!!a.purchase_base) {
            params["purchase-basis"].push(a.purchase_base.uuid);
          }
        });
      }
      if (!!contractGroup.purchase_ways) {
        contractGroup.purchase_ways.forEach((a) => {
          if (!!a.purchase_way) {
            params["purchase-ways"].push(a.purchase_way.uuid);
          }
        });
      }
      if (!!contractGroup.contract_statuses) {
        contractGroup.contract_statuses.forEach((a) => {
          if (!!a.contract_status) {
            params["contract-statuses"].push(a.contract_status.uuid);
          }
        });
      }
    }

    return params;
  };

  const {
    list: [calendarTasks, _countCalendarTasks, setCalendarTasks],
    update: [
      isUpdateCalendarTasks,
      setIsUpdateCalendarTasks,
      updateCalendarTasks,
    ],
    params: [_paramsCalendarTasks, setParamsCalendarTasks],
  } = useList({
    apiUrl: "/api/calendar-tasks",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [dayTasks, _countDayTasks, setDayTasks],
    update: [isUpdateDayTasks, setIsUpdateDayTasks, updateDayTasks],
    params: [paramsDayTasks, setParamsDayTasks],
  } = useList({
    apiUrl: "/api/tasks/calendar/day",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [dayProjectTasks, _countDayProjectTasks, setDayProjectTasks],
    update: [
      isUpdateDayProjectTasks,
      setIsUpdateDayProjectTasks,
      updateDayProjectTasks,
    ],
    params: [paramsDayProjectTasks, setParamsDayProjectTasks],
  } = useList({
    apiUrl: "/api/project-tasks/calendar/day",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [dayGeneralTasks, _countDayGeneralTasks, setDayGeneralTasks],
    update: [
      isUpdateDayGeneralTasks,
      setIsUpdateDayGeneralTasks,
      updateDayGeneralTasks,
    ],
    params: [paramsDayGeneralTasks, setParamsDayGeneralTasks],
  } = useList({
    apiUrl: "/api/general-project-tasks/calendar/day",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [overdueTasks, _countOverdueTasks, setOverdueTasks],
    update: [isUpdateOverdueTasks, setIsUpdateOverdueTasks, updateOverdueTasks],
    params: [_paramsOverdueTasks, setParamsOverdueTasks],
  } = useList({
    apiUrl: "/api/tasks/calendar/day/overdue",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [
      overdueProjectTasks,
      _countOverdueProjectTasks,
      setOverdueProjectTasks,
    ],
    update: [
      isUpdateOverdueProjectTasks,
      setIsUpdateOverdueProjectTasks,
      updateOverdueProjectTasks,
    ],
    params: [_paramsOverdueProjectTasks, setParamsOverdueProjectTasks],
  } = useList({
    apiUrl: "/api/project-tasks/calendar/day/overdue",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [
      overdueGeneralTasks,
      _countOverdueGeneralTasks,
      setOverdueGeneralTasks,
    ],
    update: [
      isUpdateOverdueGeneralTasks,
      setIsUpdateOverdueGeneralTasks,
      updateOverdueGeneralTasks,
    ],
    params: [_paramsOverdueGeneralTasks, setParamsOverdueGeneralTasks],
  } = useList({
    apiUrl: "/api/general-project-tasks/calendar/day/overdue",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [dayExecutionTasks, _countDayExecutionTasks, setDayExecutionTasks],
    update: [
      isUpdateDayExecutionTasks,
      setIsUpdateDayExecutionTasks,
      updateDayExecutionTasks,
    ],
    params: [paramsDayExecutionTasks, setParamsDayExecutionTasks],
  } = useList({
    apiUrl: "/api/tasks/calendar/day/execution",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    list: [
      overdueExecutionTasks,
      _countOverdueExecutionTasks,
      setOverdueExecutionTasks,
    ],
    update: [
      isUpdateOverdueExecutionTasks,
      setIsUpdateOverdueExecutionTasks,
      updateOverdueExecutionTasks,
    ],
    params: [_paramsOverdueExecutionTasks, setParamsOverdueExecutionTasks],
  } = useList({
    apiUrl: "/api/tasks/calendar/day/execution/overdue",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  useEffect(() => {
    setParamsCalendarTasks({ ...getUrlParams() });
    setParamsDayTasks({ ...getUrlParams() });
    setParamsDayProjectTasks({ ...getUrlParams() });
    setParamsDayGeneralTasks({ ...getUrlParams() });
    setParamsDayExecutionTasks({ ...getUrlParams() });
    if (
      toDay.format("YYYY-MM-DD") ===
      moment(selectDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    ) {
      setParamsOverdueTasks({ ...getUrlParams() });
      setParamsOverdueProjectTasks({ ...getUrlParams() });
      setParamsOverdueGeneralTasks({ ...getUrlParams() });
      setParamsOverdueExecutionTasks({ ...getUrlParams() });
    }
  }, [
    selectDate,
    selectYear,
    selectMonth,
    filterControlPoints,
    filterProjects,
    filterEmployees,
    filterCuratorEmployees,
    filterSpecialistEmployees,
    filterExecutorEmployees,
    contractGroup,
  ]);

  // Отключил, если делать то точечно.
  // useAction(["tasks", "project-tasks", "general-tasks"], () => {
  //   updateCalendarTasks();
  //   updateDayTasks();
  //   updateDayProjectTasks();
  //   updateOverdueTasks();
  //   updateOverdueProjectTasks();
  //   updateDayGeneralTasks();
  //   updateOverdueGeneralTasks();
  // });

  //--------------------------------------------------------------------------------------------------------------------

  const changeSelectMonthMinus = () => {
    setCalendarTasks(null);

    if (Number(selectMonth) > 1) {
      setSelectMonth(
        Number(selectMonth) - 1 < 10
          ? "0" + (Number(selectMonth) - 1)
          : String(Number(selectMonth) - 1)
      );
    } else {
      setSelectMonth("12");
      setSelectYear(Number(selectYear) - 1);
    }
  };

  const changeSelectMonthPlus = () => {
    setCalendarTasks(null);

    if (Number(selectMonth) < 12) {
      setSelectMonth(
        Number(selectMonth) + 1 < 10
          ? "0" + (Number(selectMonth) + 1)
          : String(Number(selectMonth) + 1)
      );
    } else {
      setSelectMonth("01");
      setSelectYear(Number(selectYear) + 1);
    }
  };

  const changeSelectDay = (day) => {
    setSelectDate(
      moment(selectYear + "-" + selectMonth + "-" + day, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      )
    );
  };

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  const monthText = (month) => {
    const months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ];

    return (
      months[Number(month) - 1].charAt(0).toUpperCase() +
      months[Number(month) - 1].slice(1)
    );
  };

  const isDayMarker = (listCalendar, selectYear, selectMonth, day, count) => {
    let isDayMarker = false;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date).format("YYYY")) === Number(selectYear) &&
          Number(moment(point.date).format("MM")) === Number(selectMonth) &&
          Number(moment(point.date).format("DD")) === Number(day)
        ) {
          if (point[count] > 0) {
            isDayMarker = true;
          }
        }
      });
    }

    return isDayMarker;
  };

  const isExecution = (
    listCalendar,
    selectYear,
    selectMonth,
    day,
    count,
    countExecution
  ) => {
    let isExecution = false;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date).format("YYYY")) === Number(selectYear) &&
          Number(moment(point.date).format("MM")) === Number(selectMonth) &&
          Number(moment(point.date).format("DD")) === Number(day)
        ) {
          if (point[count] === point[countExecution]) {
            isExecution = true;
          }
        }
      });
    }

    return isExecution;
  };

  //--------------------------------------------------------------------------------------------------------------------

  const getDayCount = (list, year, month, day) => {
    let dayCount = {
      countExecutionPoints: 0,
      countExecutionTasks: 0,
      countExecutionGenerals: 0,
      countPoints: 0,
      countTasks: 0,
      countGenerals: 0,
    };

    if (!!list) {
      list.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
            Number(year) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
            Number(month) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) ===
            Number(day)
        ) {
          dayCount.countExecutionPoints = point.count_execution_points;
          dayCount.countExecutionTasks = point.count_execution_tasks;
          dayCount.countExecutionGenerals = point.count_execution_generals;
          dayCount.countPoints = point.count_points;
          dayCount.countTasks = point.count_tasks;
          dayCount.countGenerals = point.count_generals;
        }
      });
    }

    return dayCount;
  };

  const getOptionDays = (days) => {
    const options = [];
    if (!!days) {
      days.forEach((d) => {
        options.push(d.day);
      });
    }
    return options;
  };

  const getOptions = (list, year, month, days, count, countExecution) => {
    const optionDays = [];
    if (!!days && !!list) {
      days.forEach((d) => {
        const dayCount = getDayCount(list, year, month, d);
        optionDays.push(
          Number(dayCount[count]) - Number(dayCount[countExecution])
        );
      });
    }
    return optionDays;
  };

  const getMaxOption = (options) => {
    let max = 0;
    options.map((t) => {
      if (t > max) {
        max = t;
      }
    });
    return max;
  };

  const option = (lists, year, month, days) => {
    const optionDays = getOptionDays(days);

    const optionPoints = getOptions(
      lists,
      year,
      month,
      optionDays,
      "countPoints",
      "countExecutionPoints"
    );
    const optionTasks = getOptions(
      lists,
      year,
      month,
      optionDays,
      "countTasks",
      "countExecutionTasks"
    );
    const optionGenerals = getOptions(
      lists,
      year,
      month,
      optionDays,
      "countGenerals",
      "countExecutionGenerals"
    );

    const maxGeneral =
      optionGenerals.length > 0 ? getMaxOption([...optionGenerals]) : 0;
    const maxPoint =
      optionPoints.length > 0 ? getMaxOption([...optionPoints]) : 0;
    const maxTask = optionTasks.length > 0 ? getMaxOption([...optionTasks]) : 0;

    return {
      grid: {
        left: "8px",
        right: "8px",
        bottom: "8px",
        top: "8px",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      xAxis: [
        {
          type: "category",
          data: optionDays,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          // name: 'Общие задачи',
          min: 0,
          max: maxGeneral,
          interval: maxGeneral,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          // name: 'Контрольные точки',
          min: 0,
          max: maxPoint,
          interval: maxPoint,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          // name: 'Задачи по проектам',
          min: 0,
          max: maxTask,
          interval: maxTask,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          name: "Общие задачи",
          type: "bar",
          data: optionGenerals,
          itemStyle: { color: "#cf9400" },
        },
        {
          name: "Контрольные точки",
          type: "bar",
          data: optionPoints,
          itemStyle: { color: "#0064ff" },
        },
        {
          yAxisIndex: 1,
          name: "Задачи по проектам",
          type: "bar",
          data: optionTasks,
          itemStyle: { color: "#9c27b0" },
        },
      ],
      aria: {
        enabled: true,
        decal: {
          show: true,
        },
      },
    };
  };

  const getObjectByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.uuid === filter) {
          object = o;
        }
      });
    }
    return object;
  };

  const deleteFilter = (filters, filter, setFilter) => {
    let index = null;
    if (!!filters) {
      filters.forEach((sf, i) => {
        if (sf === String(filter)) {
          index = i;
        }
      });
    }
    if (!!index || String(index) === "0") {
      filters.splice(index, 1);
      setFilter([...filters]);
    }
  };

  const getProjectByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.id === Number(filter)) {
          object = o;
        }
      });
    }
    return object;
  };

  const getEmployeeByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.account.id === Number(filter)) {
          object = o;
        }
      });
    }
    return object;
  };

  return {
    paramsDayProjectTasks,
    paramsDayTasks,
    paramsDayGeneralTasks,

    calendarTasks,
    setCalendarTasks,
    isUpdateCalendarTasks,
    setIsUpdateCalendarTasks,

    dayTasks,
    setDayTasks,
    isUpdateDayTasks,
    setIsUpdateDayTasks,

    dayProjectTasks,
    setDayProjectTasks,
    isUpdateDayProjectTasks,
    setIsUpdateDayProjectTasks,

    overdueTasks,
    setOverdueTasks,
    isUpdateOverdueTasks,
    setIsUpdateOverdueTasks,

    overdueProjectTasks,
    setOverdueProjectTasks,
    isUpdateOverdueProjectTasks,
    setIsUpdateOverdueProjectTasks,

    dayGeneralTasks,
    setDayGeneralTasks,
    isUpdateDayGeneralTasks,
    setIsUpdateDayGeneralTasks,

    overdueGeneralTasks,
    setOverdueGeneralTasks,
    isUpdateOverdueGeneralTasks,
    setIsUpdateOverdueGeneralTasks,

    dayExecutionTasks,
    setDayExecutionTasks,
    isUpdateDayExecutionTasks,
    setIsUpdateDayExecutionTasks,

    overdueExecutionTasks,
    setOverdueExecutionTasks,
    isUpdateOverdueExecutionTasks,
    setIsUpdateOverdueExecutionTasks,

    listEmployees,
    listProjects,
    listControlPoints,

    getObjectByFilter,
    deleteFilter,
    getProjectByFilter,
    getEmployeeByFilter,
    filterControlPoints,
    setFilterControlPoints,
    filterEmployees,
    setFilterEmployees,
    filterCuratorEmployees,
    setFilterCuratorEmployees,
    filterExecutorEmployees,
    setFilterExecutorEmployees,
    filterSpecialistEmployees,
    setFilterSpecialistEmployees,
    filterProjects,
    setFilterProjects,

    changeSelectMonthMinus,
    changeSelectMonthPlus,
    changeSelectDay,
    monthText,
    weekDayText,
    selectMonth,
    selectYear,
    days,
    selectDate,
    toDay,

    isDayMarker,
    isExecution,

    option,

    listContractGroups,
    contractGroup,
    setContractGroup,

    isGeneralTasks,
    setIsGeneralTasks,
    isGeneralTasksOverdue,
    setIsGeneralTasksOverdue,
    isProjectTasks,
    setIsProjectTasks,
    isProjectTasksOverdue,
    setIsProjectTasksOverdue,
    isControlPointTasks,
    setIsControlPointTasks,
    isControlPointTasksOverdue,
    setIsControlPointTasksOverdue,
    isExecutionTasks,
    setIsExecutionTasks,
    isExecutionTasksOverdue,
    setIsExecutionTasksOverdue,
  };
}

export default useTasks;
