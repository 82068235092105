import React, {useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import "./Appeals.scss";
import { AppealsMenu } from "./AppealsMenu/AppealsMenu";
import {AppealsType} from "./AppealsType";
import {AppealsMenuTree} from "./AppealsMenuTree/AppealsMenuTree";

export const AppealsRouting = () => {

  const [ object, setObject ] = useState(null);
  const [ selectEmployee, setSelectEmployee ] = useState(null);
  const [ selectCompany, setSelectCompany ] = useState(null);

  return (
    <div className="Appeals">
      <div className="Block">
        <div className="App App_vertical">
          <div className="Navigate" style={{
            width: 320,
          }}>
            <AppealsMenuTree setSelectEmployee={setSelectEmployee} setSelectCompany={setSelectCompany} />
          </div>

          <div className="Content" style={{
            boxShadow: "-8px 0 24px rgba(0,0,0, 0.05)"
          }}>
            <div className="Appeals">
              <div className="Block">
                <div className="App App_vertical">
                  <div className="Navigate" style={{
                    width: 320,
                  }}>
                    <AppealsMenu selectEmployee={selectEmployee} selectCompany={selectCompany} setObject={setObject}  />
                  </div>

                  <div className="Content" style={{
                    boxShadow: "-8px 0 24px rgba(0,0,0, 0.05)"
                  }}>
                    <Routes>
                      <Route
                        path="/:appealType"
                        element={<AppealsType selectEmployee={selectEmployee} selectCompany={selectCompany} object={object}/>}
                      />

                      <Route
                        exact
                        path="/"
                        element={<Navigate to="./all" replace/>}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
